import { useEffect, useState } from "react";

import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  IColumn,
} from "@fluentui/react/lib/DetailsList";

import { Panel } from "@fluentui/react/lib/Panel";

import { SearchBox } from "@fluentui/react/lib/SearchBox";

import { Spinner, SpinnerSize } from "@fluentui/react";

import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";
import { IButtonProps } from "@fluentui/react/lib/Button";

import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";

import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";

import { TextField } from "@fluentui/react";

import {
  addProgram,
  updateProgram,
  deleteProgram,
  getPrograms,
} from "../../services/POMAdmin";

import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

export interface IProgram {
  key: string;
  displayName: string;
  id: string;
  type: string;
}

export interface IDetailsListProgramsProps {
  orgId: string;
  modal: any;
  isGlobaleAdmin: boolean;
  currentPrograms: any;
  token: string;
  onProgramChange: Function;
}

const columnsTemp = [
  {
    key: "id",
    name: "ID",
    fieldName: "id",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "displayname",
    name: "Name",
    fieldName: "displayName",
    minWidth: 150,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "type",
    name: "Type",
    fieldName: "type",
    minWidth: 100,
    maxWidth: 200,
    isResizable: true,
  },
];

const Program = (props: IDetailsListProgramsProps) => {
  let _orgProgramsSelection: Selection;

  const [orgProgramsItems, setOrgProgramsItems] = useState<any>([]);

  const [selectionProgramsDetails, setSelectionProgramsDetails] = useState<any>(
    []
  );

  const [isNewProgramPanelOpen, setIsNewProgramPanelOpen] = useState(false);
  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);

  const [isDeleteorgProgramsDisabled, setIsDeleteorgProgramsDisabled] =
    useState(true);

  const [isEditorgProgramsDisabled, setIsEditorgProgramsDisabled] =
    useState(true);

  const [currentProgramInfo, setCurrentProgramInfo] = useState<any>();
  const [currentProgramInfoDisplayName, setCurrentProgramInfoDisplayName] =
    useState("");
  const [currentProgramInfoType, setCurrentProgramInfoType] = useState("");

  const [newProgramInfoDisplayName, setNewProgramInfoDisplayName] =
    useState("");

  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [hideDeleteDialog, setHideDeleteDialog] = useState(true);

  const token = useSelector((state: RootState) => state.token.token);

  const overflowProps: IButtonProps = { ariaLabel: "More commands" };

  useEffect(() => {
    setOrgProgramsItems(_generateOrgPrograms(props.currentPrograms));
  }, []);

  const _generateOrgPrograms = (seedItems: any) => {
    // alert("HI");
    const items: IProgram[] = [];

    var Programs = JSON.parse(seedItems.toString());

    if (Programs) {
      Programs.Programs.map((program: any, i: any) => {
        // console.log(program);
        return items.push({
          key: i.toString(),
          displayName: program.DisplayName,
          id: program.ID,
          type: program.Properties.Type,
        });
      });
    }

    return items;
  };

  _orgProgramsSelection = new Selection({
    onSelectionChanged: () => {
      const selectionorgProgramsCount =
        _orgProgramsSelection.getSelectedCount();
      if (selectionorgProgramsCount === 1) {
        setIsDeleteorgProgramsDisabled(false);
        setIsEditorgProgramsDisabled(false);

        setSelectionProgramsDetails(_orgProgramsSelection.getSelection());
      } else {
        setIsDeleteorgProgramsDisabled(true);
        setIsEditorgProgramsDisabled(true);
      }
    },
  });

  const showNewProgramPanel = () => {
    setIsNewProgramPanelOpen(true);
  };

  const closePanel = () => {
    setIsNewProgramPanelOpen(false);
  };

  const openDeleteProgramForm = () => {
    let temp = selectionProgramsDetails[0].key;

    let selectedPro: any = orgProgramsItems.filter(
      (item: any) => item.key === temp
    );

    setCurrentProgramInfoDisplayName(selectedPro[0].displayName);

    setCurrentProgramInfo(selectedPro[0]);
    setHideDeleteDialog(false);
  };

  const openProgramEditForm = () => {
    let temp = selectionProgramsDetails[0].key;

    let selectedPro: any = orgProgramsItems.filter(
      (item: any) => item.key === temp
    );

    setCurrentProgramInfoDisplayName(selectedPro[0].displayName);
    setCurrentProgramInfoType(selectedPro[0].type);
    setCurrentProgramInfo(selectedPro[0]);
    setIsEditPanelOpen(true);
  };

  const _orgProgramsItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: showNewProgramPanel,
      disabled: false,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
    {
      key: "edit",
      text: "Edit",
      onClick: openProgramEditForm,
      iconProps: { iconName: "Edit" },
      disabled: isEditorgProgramsDisabled,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
    {
      key: "delete",
      text: "Delete",
      onClick: openDeleteProgramForm,
      iconProps: { iconName: "Delete" },
      disabled: isDeleteorgProgramsDisabled,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
  ];

  const _getKey = (item: any): string => {
    return item.key;
  };

  const _onRenderFooterContent = (): JSX.Element => {
    var _r = <div>Footer</div>;

    _r = (
      <div>
        <PrimaryButton onClick={submitProgram} style={{ marginRight: "8px" }}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={closePanel} style={{ marginRight: "8px" }}>
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const submitProgram = () => {
    if (newProgramInfoDisplayName.trim() === "") {
    } else {
      setShowLoadingMessage(true);
      addProgram(newProgramInfoDisplayName, currentProgramInfoType, token).then(
        (programInfo) => {
          console.log(programInfo);
          setIsNewProgramPanelOpen(false);
          setShowLoadingMessage(false);

          let _tmp: any = [...orgProgramsItems];

          let _type = "";
          if (programInfo["Properties"]["Type"]) {
            _type = programInfo["Properties"]["Type"];
          }

          if (programInfo["ID"]) {
            _tmp.push({
              key: (_tmp.length + 1).toString(),
              displayName: programInfo["DisplayName"],
              id: programInfo["ID"],
              type: _type,
            });
          }

          setOrgProgramsItems([..._tmp]);
          setNewProgramInfoDisplayName("");
          setCurrentProgramInfoType("");
        }
      );
    }
  };

  const _onChangeNewProgramName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    //console.log(newValue);
    setNewProgramInfoDisplayName(newValue || "");
  };

  const _onChangeEditProgramName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    //console.log(newValue);
    setCurrentProgramInfoDisplayName(newValue || "");
  };

  const _onChangeEditProgramType = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    //console.log(newValue);
    setCurrentProgramInfoType(newValue || "");
  };

  //
  const _onRenderFooterEditContent = (): JSX.Element => {
    var _r = <div>Footer</div>;

    _r = (
      <div>
        <PrimaryButton onClick={editProgram} style={{ marginRight: "8px" }}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={closePanel} style={{ marginRight: "8px" }}>
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const editProgram = () => {
    setShowLoadingMessage(true);

    let _p = {
      ID: currentProgramInfo.id,
      DisplayName: currentProgramInfoDisplayName,
      Properties: {
        Type: currentProgramInfoType,
      },
    };

    updateProgram(_p, token).then((programInfo) => {
      console.log(programInfo);

      setIsEditPanelOpen(false);

      getPrograms(token).then((orgInfo: any) => {
        var _obj = JSON.stringify(orgInfo);

        let _p = _generateOrgPrograms(_obj);

        setOrgProgramsItems([..._p]);

        setShowLoadingMessage(false);
        setCurrentProgramInfoDisplayName("");
        setCurrentProgramInfoType("");
        props.onProgramChange(programInfo.ID, currentProgramInfoDisplayName, currentProgramInfoType);
      });
    });
  };

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  //
  const deleteProgramTemp = () => {
    setShowLoadingMessage(true);
    setHideDeleteDialog(true);

    deleteProgram(currentProgramInfo.id, token).then((programInfo) => {
      getPrograms(token).then((orgInfo: any) => {
        var _obj = JSON.stringify(orgInfo);

        if (orgInfo["ExceptionMessage"]) {
          alert(orgInfo["ExceptionMessage"]);
        }

        let _p: any = _generateOrgPrograms(_obj);

        setOrgProgramsItems([..._p]);

        setShowLoadingMessage(false);
      });
    });
  };

  return (
    <>
      <CommandBar
        items={_orgProgramsItems}
        overflowButtonProps={overflowProps}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      {showLoadingMessage ? (
        <Spinner
          style={{ height: "100%", fontSize: "20px" }}
          label="updating..."
          size={SpinnerSize.large}
        ></Spinner>
      ) : (
        ""
      )}

      <MarqueeSelection selection={_orgProgramsSelection}>
        <DetailsList
          items={orgProgramsItems}
          compact={false}
          columns={columnsTemp}
          selectionMode={1}
          getKey={_getKey}
          setKey="multiple"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
          selection={_orgProgramsSelection}
          selectionPreservedOnEmptyClick={true}
          enterModalSelectionOnTouch={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
        />
      </MarqueeSelection>

      <Panel
        headerText={"New Program"}
        isOpen={isNewProgramPanelOpen}
        onDismiss={closePanel}
        closeButtonAriaLabel="Close"
        isFooterAtBottom={false}
        onRenderFooterContent={_onRenderFooterContent}
      >
        <label htmlFor="Program" />
        <TextField
          label={"Program"}
          value={newProgramInfoDisplayName}
          onChange={_onChangeNewProgramName}
        ></TextField>

        <label htmlFor="Type" />
        <TextField
          label={"Type"}
          value={currentProgramInfoType}
          onChange={_onChangeEditProgramType}
        ></TextField>
      </Panel>
      <Panel
        headerText={"Edit Program"}
        isOpen={isEditPanelOpen}
        onDismiss={() => setIsEditPanelOpen(false)}
        closeButtonAriaLabel="Close"
        isFooterAtBottom={false}
        onRenderFooterContent={_onRenderFooterEditContent}
      >
        <label htmlFor="Program" />
        <TextField
          label={"Program"}
          value={currentProgramInfoDisplayName}
          onChange={_onChangeEditProgramName}
          autoComplete="off"
        ></TextField>

        <label htmlFor="Type" />
        <TextField
          label={"Type"}
          value={currentProgramInfoType}
          onChange={_onChangeEditProgramType}
          autoComplete="off"
        ></TextField>
      </Panel>
      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={modelProps}
      >
        <div>
          Are you sure you want to delete this program:
          <b> {currentProgramInfoDisplayName}</b> ?
          <br />
          <br />
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={deleteProgramTemp}
            text="Delete"
            style={{
              backgroundColor: "red",
            }}
          />
          <DefaultButton
            onClick={() => setHideDeleteDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Program;
