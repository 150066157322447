import { useEffect, useState } from "react";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import classes from "./PartnerManager.module.css";

import { Icon } from "@fluentui/react/lib/Icon";
import {
  Spinner,
  SpinnerSize,
  ChoiceGroup,
  TextField,
  PrimaryButton,
  DefaultButton,
  IButtonProps,
} from "@fluentui/react";

import { getAdmins, updateAdminsOrganization } from "../../services/POMAdmin";
import { Panel } from "@fluentui/react/lib/Panel";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Checkbox } from "@mui/material";

import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";

import Error from "../Exceptions/TokenError";

import defaultStore from '../../util/Globale';

export interface IDetailsListMembershipsProps {
  currentOrg: any;
  isOrgAdmin: boolean;
  isGlobaleAdmin: boolean;
  token: string;
}

const Administrators = (props: any) => {
  
  let _adminSearchCount = 0;
  let _adminsArr: any;
  
  let errText: string = "";

  const token = useSelector((state: RootState) => state.token.token);
  const [adminItems, setAdminItems] = useState<any>([]);

  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState<boolean>(false);
  const [isDeleteAdministratorsDisabled, setIsDeleteAdministratorsDisabled] =
    useState<boolean>(true);

  const [isAddAdminDisabled, setIsAddAdminDisabled] = useState<boolean>(false);

  const [admin, setAdmin] = useState<any>({});
  const [adminError, setAdminError] = useState<string>("");

  const [foundAdmins, setFoundAdmins] = useState<any>([]);

  const [showLoading, setShowLoading] = useState<boolean>(true);

  const [showMemberSearchMessage, setShowMemberSearchMessage] =
    useState<boolean>(false);
  const [selectedAdminDelete, setSelectedAdminDelete] = useState<any>({});

  const [hideAdminDeleteDialog, setHideAdminDeleteDialog] =
    useState<boolean>(true);
  const [checkboxValue, setCheckboxValue] = useState<string>("");
  const [selectedKey, setSelectedKey] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [alladminItems, setAlladminItems] = useState<any>()

  useEffect(() => {
    if (props.currentOrg) {
      getAdmins(props.currentOrg.ID, token)
        .then((response) => {
          //console.log("response members CDM", response.Administrators);

          setAdminItems(response.Administrators);
          setShowLoading(false);
          setSelectedKey(false);
          setIsDeleteAdministratorsDisabled(true);

          setAlladminItems(response.Administrators);
        })
        .catch((err: any) => {
          // console.log(err);
          errText = err;
          setError(true);
        });
    }
  }, [props.currentOrg]);

  const columnSort = (column: any) => {
    //alert("clicked");
    let obj = [...adminItems];
    //console.log(this.state.sortAsc);
    //console.log(column);

    if (sortAsc) {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? 1 : -1;
      });
    } else {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? -1 : 1;
      });
    }

    // console.log(obj);
    setAdminItems([...obj]);

    setSortAsc(!sortAsc);
  };

  const deleteAdmin = () => {
    _adminsArr = [];

    adminItems.map((item: any) => {
      return _adminsArr.push(item.ID);
    });

    let index = _adminsArr.indexOf(selectedAdminDelete.ID);

    if (index > -1) {
      _adminsArr.splice(index, 1);
    }

    setShowLoading(true);
    setHideAdminDeleteDialog(true);
    updateAdminsOrganization(props.currentOrg.ID, _adminsArr, token)
      .then((res) => {
        //console.log(res)
        getAdmins(props.currentOrg.ID, token)
          .then((_r) => {
            setAdminItems(_r.Administrators);

            setShowLoading(false);
            setSelectedKey(false);
            setIsDeleteAdministratorsDisabled(true);
          })
          .catch((err: any) => {
            errText = err;
            setError(true);
          });
      })
      .catch((err: any) => {
        //console.log(err)
        errText = err;
        setError(true);
      });
  };

  const onAdminChange = (event: any): void => {
    //console.log(event);
    //console.log(event.target.value);

    if (
      event.target.value.length === 3 ||
      event.target.value.length === 5 ||
      event.target.value.length === 7
    ) {
      setShowMemberSearchMessage(true);

      _adminSearchCount++;
      let thisCount = _adminSearchCount;

      validateUser(event.target.value).then((Users) => {
        // console.log("Validated ...");
        // console.log(Users);

        if (Users["ErrorMessage"]) {
          setAdminError(Users["ErrorMessage"]);
        } else if (Users["ExceptionMessage"]) {
          setAdminError(Users["ExceptionMessage"]);
        }

        if (thisCount === _adminSearchCount) {
          if (Users.length > 0) {
            let _admins: any[] = [];

            for (var i = 0; i < Users.length; i++) {
              let _adminsTmp = {
                key: "",
                text: "",
              };

              _adminsTmp["key"] = Users[i]["ID"];
              _adminsTmp["text"] =
                Users[i]["DisplayName"] + " (" + Users[i]["Email"] + ")";

              _admins.push(_adminsTmp);
            }

            //console.log(_admins);

            setShowMemberSearchMessage(false);
            setFoundAdmins(_admins);

            if (event.target.value.length > 0) {
              setAdminError("");
            } else {
              setAdminError("Enter an administrator");
            }
          }
        }
      });
    }
  };
  const validateUser = async (user: string) => {
    const response = await fetch(
      defaultStore.webAPIMainEndPoint + "/api/OutReach/people?find=" +
        user,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json;odata=verbose",
          Authorization: "Bearer " + token,
          "X-app-location": window.location.origin + window.location.pathname,
        },
      }
    );

    const Users = await response.json();

    return Users;
  };

  const onChangeCheckbox = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    request: any
  ) => {
    setSelectedKey(checked);
    setCheckboxValue(ev.target.value);

    if (checked) {
      setIsDeleteAdministratorsDisabled(false);
      setSelectedAdminDelete(request);
    } else {
      setIsDeleteAdministratorsDisabled(true);
    }
  };

  const _onSearchChange = (text: any): void => {
    setAdminItems(
      !!text
        ? alladminItems.filter(
            (i: any) =>
              i.DisplayName.toLowerCase().indexOf(text.toLocaleLowerCase()) > -1
          )
        : alladminItems
    );
  };

  const _onRenderAdminFooterContent = (): JSX.Element => {
    var _r = <div>Footer</div>;

    _r = (
      <div>
        <PrimaryButton
          disabled={Object.keys(admin).length === 0}
          onClick={submitAdmin}
          style={{ marginRight: "8px" }}
        >
          Save Admin
        </PrimaryButton>
        <DefaultButton
          onClick={() => setIsAdminPanelOpen(false)}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const submitAdmin = () => {
    _adminsArr = [];
    adminItems.map((item: any) => {
      return _adminsArr.push(item.ID);
    });

    _adminsArr.push(admin.key);

    setShowLoading(true);
    setAdmin({});
    setIsAdminPanelOpen(false);

    updateAdminsOrganization(props.currentOrg.ID, _adminsArr, token).then(
      (adminInfo) => {
        if (adminInfo["ErrorMessage"]) {
          alert(adminInfo["ErrorMessage"]);
        } else if (adminInfo["ExceptionMessage"]) {
          alert(adminInfo["ExceptionMessage"]);
        }

        getAdmins(props.currentOrg.ID, token).then((_r) => {
          setAdminItems(_r.Administrators);
          setShowLoading(false);
        });
      }
    );
  };

  const onClickHandler = () => {
    setIsAdminPanelOpen(true);
    setFoundAdmins([]);
    setAdmin({});
  };

  const _adminItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: onClickHandler,

      disabled: isAddAdminDisabled || !props.isGlobaleAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => setHideAdminDeleteDialog(false),
      iconProps: { iconName: "Delete" },
      disabled: isDeleteAdministratorsDisabled || !props.isOrgAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
  ];

  const _farItems: ICommandBarItemProps[] = [
    {
      key: "tile",
      onRender: () => (
        <SearchBox
          placeholder="Filter by admin name"
          className="searchBox"
          onSearch={(newValue) => _onSearchChange(newValue)}
          onChange={(ev) => _onSearchChange(ev?.target?.value)}
        />
      ),
    },
  ];

  const overflowProps: IButtonProps = {
    ariaLabel: "More commands",
    menuIconProps: {
      iconName: "ChevronDown",
    },
    styles: {
      menuIcon: {
        fontSize: "20px",
        color: "#0078d4",
      },
    },
  };

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  const dialogContentProps = {
    type: DialogType.close,
    title: "Delete Administrator",
    subText: "Are you sure you want to delete this Administrator?",
    style: { title: { color: "red" }, content: { color: "black" } },
  };

  return (
    <div style={{ margin: "1%" }}>
      <p style={{ fontSize: "20px", color: "#006cb9" }}>
        Administrators: {props.currentOrg.DisplayName}
      </p>
      <CommandBar
        items={_adminItems}
        farItems={_farItems}
        overflowButtonProps={overflowProps}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      <Table className={classes.tableList}>
        <Thead style={{ textAlign: "left" }}>
          <Tr>
            <Th>Select</Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("DisplayName")}
            >
              Administrator Name{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("Email")}
            >
              Administrator Email{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("MobilePhone")}
            >
              Phone{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
          </Tr>
        </Thead>
        {!showLoading && (
          adminItems.length > 0 && (
            adminItems.map((request: any, i: any) => {
              return (
                <Tbody key={request.ID + i}>
                  <Tr key={request.Created}>
                    <Td>
                      <Checkbox
                        value={i}
                        className={classes.checkBox}
                        checked={
                          i.toString() === checkboxValue ? selectedKey : false
                        }
                        onChange={(e, checked) => {
                          onChangeCheckbox(e, checked, request);
                        }}
                      />
                    </Td>
                    <Td>{request.DisplayName}</Td>
                    <Td>{request.Email}</Td>
                    <Td>{request.MobilePhone}</Td>
                  </Tr>
                </Tbody>
              );
            })
          )
        )}
      </Table>
      {showLoading && (
        <div className={classes.spinnerPos}>
            <Spinner size={SpinnerSize.large}></Spinner>
        </div>)
      }
      {!showLoading && adminItems.length == 0 && (
        <div className={classes.spinnerPos}>
          <b>List Empty</b>
        </div>)
      }
      {error && <Error text={errText} customText="Not able to load." />}

      <Panel
        headerText={"New Administrator"}
        isOpen={isAdminPanelOpen}
        onDismiss={() => setIsAdminPanelOpen(false)}
        closeButtonAriaLabel="Close"
        isFooterAtBottom={false}
        onRenderFooterContent={_onRenderAdminFooterContent}
      >
        <br />
        <TextField
          label={"Administrator Name"}
          placeholder="Enter admin name"
          errorMessage={adminError}
          onChange={onAdminChange}
          required
        ></TextField>
        {showMemberSearchMessage ? (
          <Spinner
            style={{ fontSize: "16px", padding: "10px" }}
            size={SpinnerSize.large}
          ></Spinner>
        ) : (
          ""
        )}
        <ChoiceGroup
          options={foundAdmins}
          required={true}
          onChange={(ev, option: any) => {
            setAdmin(option);
          }}
        />
      </Panel>

      <Dialog
        hidden={hideAdminDeleteDialog}
        onDismiss={() => setHideAdminDeleteDialog(true)}
        modalProps={modelProps}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={deleteAdmin}
            text="Delete"
            style={{
              backgroundColor: "red",
            }}
          />
          <DefaultButton
            onClick={() => setHideAdminDeleteDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Administrators;
