import { useEffect, useState } from "react";
import classes from "./PartnerManager.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { Panel } from "@fluentui/react/lib/Panel";

import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Checkbox } from "@mui/material";
import { Spinner, SpinnerSize } from "@fluentui/react";

import {
  getProgramStudentRequests,
  processRequest,
} from "../../services/POMAdmin";

import { TextField } from "@fluentui/react";

import { Dialog, DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import {
  DefaultButton,
  PrimaryButton,
  IButtonProps,
} from "@fluentui/react/lib/Button";

import Error from "../Exceptions/TokenError";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

import defaultStore from '../../util/Globale';

export interface IStudentRequest {
  key: string;
  ID: string;
  Decision: string;
  Created: string;
  ExpireDate: string;
  IsExpired: string;
  RequestInfo: string;
  StudentName: string;
  StudentNumber: string;
  LegalGuardian: string;
  LegalGuardianEmail: string;
  LegalGuardianID: string;
  ProgramDisplayName: string;
  StudentDOB: string;
  StudentSchool: string;
  StudentGrade: string;
}

export interface IDetailsListStudentsProgramsRequestsProps {
  currentOrg: any;
  token: string;
  isOrgAdmin: boolean;
  isGlobaleAdmin: boolean;
}

const StudentsProgramsRequests = (
  props: IDetailsListStudentsProgramsRequestsProps
) => {
  // let _allItems: any[] = [];

  let errText: string = "";

  const token = useSelector((state: RootState) => state.token.token);

  const [allItems, setAllItems] = useState<any>([]);
  const [isDenyDisabled, setIsDenyDisabled] = useState(true);
  const [isRetractDisabled, setIsRetractDisabled] = useState(true);

  const [isApproveDisabled, setIsApproveDisabled] = useState(true);
  const [isViewDisabled, setIsViewDisabled] = useState(true);

  const [hideApproveDialog, setHideApproveDialog] = useState(true);
  const [hideDenyDialog, setHideDenyDialog] = useState(true);
  const [hideRetractDialog, setHideRetractDialog] = useState(true);
  const [showViewPanel, setShowViewPanel] = useState(false);

  const [currentOrgStudentRequests, setCurrentOrgStudentRequests] =
    useState<any>({});

  const [decisionComment, setDecisionComment] = useState("");

  const [showLoading, setShowLoading] = useState(true);
  const [selectedKey, setSelectedKey] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState("");
  const [sortAsc, setSortAsc] = useState<boolean>(true);

  const [selectedStudent, setSelectedStudent] = useState<any>({});
  const [error, setError] = useState(false);

  useEffect(() => {
    getProgramStudentRequests(props.currentOrg.ID, props.token)
      .then((response) => {
        setCurrentOrgStudentRequests(
          _generateStudentsProgramsRequests(response)
        );

        setShowLoading(false);
        setSelectedKey(false);
        setIsViewDisabled(true);
        setIsApproveDisabled(true);
        setIsDenyDisabled(true);
        setIsRetractDisabled(true);

        setAllItems(_generateStudentsProgramsRequests(response));
      })
      .catch((err: any) => {
        console.log(err);
        errText = err;
        setError(true);
      });
  }, [props.currentOrg]);

  const _items: ICommandBarItemProps[] = [
    {
      key: "view",
      text: "View",
      iconProps: { iconName: "View" },
      onClick: () => setShowViewPanel(true),
      disabled: isViewDisabled,
    },
    {
      key: "approve",
      text: "Approve",
      iconProps: { iconName: "CheckMark", color: "rgb(186, 184, 183)" },
      onClick: () => setHideApproveDialog(false),
      disabled: isApproveDisabled || !props.isOrgAdmin,
      style: {
        color: isApproveDisabled ? "rgb(186, 184, 183)" : "green",
      },
    },
    {
      key: "deny",
      text: "Deny",
      onClick: () => setHideDenyDialog(false),
      iconProps: {
        iconName: "Blocked",
        color: isDenyDisabled ? "rgb(186, 184, 183)" : "red",
      },
      disabled: isDenyDisabled || !props.isOrgAdmin,
      style: {
        color: isDenyDisabled ? "rgb(186, 184, 183)" : "red",
      },
    },
    {
      key: "retract",
      text: "Retract",
      onClick: () => setHideRetractDialog(false),
      iconProps: {
        iconName: "Delete",
      },
      disabled: isRetractDisabled || !props.isOrgAdmin,
    },
  ];

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  const overflowProps: IButtonProps = {
    ariaLabel: "More commands",
    menuIconProps: {
      iconName: "ChevronDown",
    },
    styles: {
      menuIcon: {
        fontSize: "20px",
        color: "#0078d4",
      },
    },
  };
  const dialogContentProps = {
    type: DialogType.close,
    // title: "Delete Program",
    // subText: "Are you sure you want to delete this program?",
    // style: { title: { color: "red" }, content: { color: "black" } },
  };

  const _generateStudentsProgramsRequests = (requests: any) => {
    const items: IStudentRequest[] = [];

    //console.log(" >>> seedItems >> ");
    //console.log(requests);

    requests.map((student: any, i: any) => {
      return items.push({
        key: i.toString(),
        ID: student.ID,
        Decision: student.Decision,
        Created: formatted_date(student.Created),
        ExpireDate: formatted_date(student.FormExpiration),
        IsExpired: student.IsFormExpired ? "Yes" : "No",
        RequestInfo: student.DisplayName,
        StudentName: student.StudentInfo.DisplayName,
        StudentNumber: student.StudentInfo.ID,
        LegalGuardian: student.RequestorInfo.DisplayName,
        LegalGuardianEmail: student.RequestorInfo.Email,
        LegalGuardianID: student.RequestorInfo.ID,
        ProgramDisplayName: student.ProgramDisplayName,
        StudentDOB: formatted_date(student.StudentInfo.DateOfBirth),
        StudentSchool: student.StudentInfo.School,
        StudentGrade: student.StudentInfo.GradeLevel,
      });
    });

    return items;
  };

  const columnSort = (column: any) => {
    //alert("clicked");
    let obj = [...currentOrgStudentRequests];

    //console.log(column, sortAscTemp);

    if (sortAsc) {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? 1 : -1;
      });
    } else {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? -1 : 1;
      });
    }

    //console.log(obj);

    setCurrentOrgStudentRequests([...obj]);
    //console.log("after sort",currentOrgStudentRequests[0]);

    setSortAsc(!sortAsc);
  };

  const columnStyling = (decision: string) => {
    // console.log(decision);

    switch (decision) {
      case "Approved":
        return classes.approved;

      case "Denied":
        return classes.denied;

      case "Retracted":
        return classes.retracted;

      default:
        return classes.pending;
    }
  };

  const retractRequest = async () => {
    setShowLoading(true);
    setHideRetractDialog(true);

    let _r = {
      ID: selectedStudent.ID,
    };

    const response = await fetch(
      defaultStore.webAPIMainEndPoint + "/api/outreach/retractprogramrequest",
      {
        method: "POST",
        body: JSON.stringify(_r),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json;odata=verbose",
          Authorization: "Bearer " + token,
          "X-app-location": window.location.origin + window.location.pathname,
        },
      }
    );

    const requestInfo = await response.json();

    setShowLoading(false);

    //  console.log(" ... response.json() .. ");
    // console.log(requestInfo);

    getProgramStudentRequests(props.currentOrg.ID, token).then((_r) => {
      setCurrentOrgStudentRequests(_generateStudentsProgramsRequests(_r));
      setSelectedKey(false);
      setIsViewDisabled(true);
      setIsApproveDisabled(true);
      setIsDenyDisabled(true);
      setIsRetractDisabled(true);
    });
  };
  const commitRequest = (decision: any) => {
    setHideApproveDialog(true);
    setHideDenyDialog(true);

    setShowLoading(true);

    // console.log(this.state.selectedStudent.ID);

    let _r = {
      ID: selectedStudent.ID,
      Organization: props.currentOrg.ID,
      Decision: decision,
      DecisionText: decisionComment,
    };

    processRequest(_r, token).then((programInfo) => {
      setShowLoading(false);

      //console.log(" >>>>>>>> programInfo >>>>>>>>>>>>");
      //console.log(programInfo);

      if (programInfo.ExceptionMessage) {
        alert(programInfo.ExceptionMessage);
      }
      getProgramStudentRequests(props.currentOrg.ID, token).then((_r) => {
        setCurrentOrgStudentRequests(_generateStudentsProgramsRequests(_r));
        setSelectedKey(false);
        setIsViewDisabled(true);
        setIsApproveDisabled(true);
        setIsDenyDisabled(true);
        setIsRetractDisabled(true);
      });
    });
  };

  const updateDecisionText = (event: any): void => {
    // console.log(">>> newTppppext >>");
    // console.log(event);
    // console.log(event.target.value);

    setDecisionComment(event.target.value);
  };

  const onChangeCheckbox = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    request: any
  ) => {
    // console.log(typeof(ev.target.value), typeof(index));

    setSelectedKey(checked);
    setCheckboxValue(ev.target.value);
    setSelectedStudent(request);

    if (checked && request.Decision === "Pending") {
      setIsViewDisabled(false);
      setIsApproveDisabled(false);
      setIsDenyDisabled(false);
      setIsRetractDisabled(false);
    } else if (checked && request.Decision !== "Pending") {
      setIsViewDisabled(false);
      setIsApproveDisabled(true);
      setIsDenyDisabled(true);
      setIsRetractDisabled(true);
    } else {
      setIsViewDisabled(true);
      setIsApproveDisabled(true);
      setIsDenyDisabled(true);
      setIsRetractDisabled(true);
    }
  };
  const _farItems: ICommandBarItemProps[] = [
    {
      key: "tile",
      onRender: () => (
        <SearchBox
          placeholder="Filter by student name"
          className="searchBox"
          onSearch={(newValue) => {_onSearchChange(newValue)}}
          onChange={(ev) => _onSearchChange(ev?.target?.value)}
        />
      ),
    },
  ];

  const _onSearchChange = (text: any): void => {
    setCurrentOrgStudentRequests(
      !!text
        ? allItems.filter(
            (i: any) =>
              i.StudentName.toLowerCase().indexOf(text.toLocaleLowerCase()) > -1
          )
        : allItems
    );
  };

  const formatted_date = (date: any) => {
    // console.log(date);
    let _d = new Date(date);
    let formatted_date =
      _d.getMonth() + 1 + "/" + _d.getDate() + "/" + _d.getFullYear();

    //console.log(formatted_date);
    return formatted_date;
  };

  return (
    <div style={{ margin: "1%" }}>
      <p style={{ fontSize: "20px", color: "#006cb9" }}>
        Student Requests: {props.currentOrg.DisplayName}
      </p>

      <CommandBar
        items={_items}
        farItems={_farItems}
        overflowButtonProps={overflowProps}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      <Table className={classes.tableList}>
        <Thead>
          <Tr>
            <Th>Select</Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("Decision")}
            >
              Status
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("Created")}
            >
              Created
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>

            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("StudentName")}
            >
              Student Name{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("StudentNumber")}
            >
              Student ID
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("LegalGuardian")}
            >
              Guardian{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th onClick={() => columnSort("LegalGuardianEmail")}>
              Guardian Email{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>

            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("StudentSchool")}
            >
              Student School{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("StudentGrade")}
            >
              Student Grade
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
          </Tr>
        </Thead>
        {!showLoading && (
          currentOrgStudentRequests.length > 0 && (
            currentOrgStudentRequests.map((request: any, i: any) => {
              return (
                <Tbody key={request.key}>
                  <Tr key={request.key}>
                    <Td>
                      <Checkbox
                        className={classes.checkBox}
                        value={i}
                        checked={
                          i.toString() === checkboxValue ? selectedKey : false
                        }
                        onChange={(e, checked) => {
                          onChangeCheckbox(e, checked, request);
                        }}
                      />
                    </Td>
                    <Td className={columnStyling(request.Decision)}>
                      <b>{request.Decision}</b>
                    </Td>
                    <Td>{request.Created}</Td>

                    <Td>
                      <span>{request.StudentName}</span>
                    </Td>
                    <Td>{request.StudentNumber}</Td>
                    <Td>{request.LegalGuardian}</Td>
                    <Td>{request.LegalGuardianEmail}</Td>

                    <Td>{request.StudentSchool}</Td>
                    <Td>{request.StudentGrade}</Td>
                  </Tr>
                </Tbody>
              );
            })
          )
        )}
      </Table>
      {showLoading && (
        <div className={classes.spinnerPos}>
            <Spinner size={SpinnerSize.large}></Spinner>
        </div>)
      }
      {!showLoading && currentOrgStudentRequests.length == 0 && (
        <div className={classes.spinnerPos}>
          <b>List Empty</b>
        </div>)
      }
      {error && <Error text={errText} customText="Not able to load." />}

      {selectedKey ? (
        <Panel
          headerText={"Request Info"}
          isOpen={showViewPanel}
          onDismiss={() => setShowViewPanel(false)}
          closeButtonAriaLabel="Close"
        >
          <br />
          <h4> Program: </h4>
          <p>{selectedStudent.ProgramDisplayName}</p>
          <hr />
          <h4>Student:</h4>
          <p>{selectedStudent.StudentName}</p>
          <p>
            <b>DOB:</b> {formatted_date(selectedStudent.StudentDOB)}
          </p>
          <p>
            <b>Grade:</b> {selectedStudent.StudentGrade}
          </p>
          <p>
            <b>School: </b>
            {selectedStudent.StudentSchool}
          </p>
          <hr />
          <h4>Requestor: </h4>
          <p>{selectedStudent.LegalGuardian}</p>
          <p>
            <b>Email:</b> {selectedStudent.LegalGuardianEmail}
          </p>
          <p>
            <b>ID: </b>
            {selectedStudent.LegalGuardianID}
          </p>
        </Panel>
      ) : null}

      <Dialog
        hidden={hideApproveDialog}
        onDismiss={() => setHideApproveDialog(true)}
        modalProps={modelProps}
        dialogContentProps={dialogContentProps}
      >
        <div>
          Are you sure you want to approve the request?
          <br />
          <br />
          <b>{selectedStudent.RequestInfo}</b>
          <br />
          <br />
          <TextField
            label="Comment"
            multiline
            rows={3}
            onChange={updateDecisionText}
          />
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={() => commitRequest("Approved")}
            text="Approve"
          />
          <DefaultButton
            onClick={() => setHideApproveDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
      <Dialog
        hidden={hideDenyDialog}
        onDismiss={() => setHideDenyDialog(true)}
        modalProps={modelProps}
        dialogContentProps={dialogContentProps}
      >
        <div>
          Are you sure you want to deny the request?
          <br />
          <br />
          <b> {selectedStudent.RequestInfo}</b>
          <br />
          <br />
          <TextField
            label="Comment"
            multiline
            rows={3}
            onChange={updateDecisionText}
          />
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={() => commitRequest("Denied")}
            text="Deny"
            style={{
              backgroundColor: "red",
            }}
          />
          <DefaultButton
            onClick={() => setHideDenyDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={hideRetractDialog}
        onDismiss={() => setHideRetractDialog(true)}
        modalProps={modelProps}
      >
        <div>
          Are you sure you want to retract the request?
          <br />
          <br />
          <b>{selectedStudent.RequestInfo}</b>
          <br />
        </div>
        <DialogFooter>
          <PrimaryButton
            onClick={retractRequest}
            text="Retract"
            style={{
              backgroundColor: "red",
            }}
          />
          <DefaultButton
            onClick={() => setHideRetractDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default StudentsProgramsRequests;
