import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface tokenState {
  token: string,
  expiration: string,
}


const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token: "",
    tokenExpiration: ""
  },
  reducers: {
    updateToken(state, action: PayloadAction<tokenState>) {
      console.log(action);
      state.token = action.payload.token;
      state.tokenExpiration= action.payload.expiration
    },
  },
});

export const tokenActions = tokenSlice.actions;

export default tokenSlice;
