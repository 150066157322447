import { Route, Routes, HashRouter } from "react-router-dom";
import "../style/App.css";
import HomeRoomAppBar from "../components/appBar/AppNavBar";
import AppFooter from "../components/appBar/AppFooter";
import POMAdmin from "../pages/PartnerManager/PartnerManager";
import PomParents from "../pages/FERPA/FERPA";
import { CssBaseline } from "@mui/material";
import TokenError from "../pages/Exceptions/TokenError";
import DefaultError from "../pages/Exceptions/DefaultError";
import GenericNotFound from "../pages/Exceptions/GenericNotFound";
import { useSelector } from "react-redux";

import { RootState } from "../store";

import ThemeProvider from "../style/Theme";


const AppRoot = () => {
  //console.log(props.authenticationError);
  const token = useSelector((state: RootState) => state.token.token);
  const error = useSelector((state: RootState) => state.error.error);

  return (
    <HashRouter>
      <div className={"drawerController"}>
        <ThemeProvider>
          <CssBaseline />
          <HomeRoomAppBar />
          <main className={"mainDivRouter"}>
            {error.fatal ? (
              token === "" ? (
                <TokenError
                  text={error.message}
                  code={error.status}
                  customText={error.errorType}
                />
              ) : (
                <DefaultError />
              )
            ) : (
              <Routes>
                <Route path="/ferpa" element={<PomParents/>}/>
                <Route path="/pomadmin" element={<POMAdmin/>}/>
                <Route path="*" element={<GenericNotFound />} />
              </Routes>
            )}
            <AppFooter />
          </main>
        </ThemeProvider>
      </div>
    </HashRouter>
  );
};

export default AppRoot;
