import defaultStore from "../util/Globale";


export async function getMyStudentsList(token:string) {
  const response = await fetch(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/MyStudents",
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json;odata=verbose",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    }
  );

  const students = await response.json();
  return students;
}

export async function getProgramsList(token:string) {
  const response = await fetch(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Programs",
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json;odata=verbose",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    }
  );

  const programs = await response.json();

  return programs;
}

export async function getMyRequestsList(token: string) {
  const response = await fetch(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/StudentProgramRequests",
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json;odata=verbose",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    }
  );

  const requests = await response.json();

  return requests;
}

export async function postMyRequestsList(token: string, data: any){

  const response = await fetch(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/StudentProgramRequests",
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json;odata=verbose",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    }
  );

  const students =  await response.json();

  return students;
  
}
