export function formatDate(_date: string): string {
  //console.log(_date);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let current_datetime = new Date(_date);

  let timeStamp = current_datetime.toLocaleString().split(",")[1];
  let formatted_date =
    current_datetime.getDate() +
    " " +
    months[current_datetime.getMonth()] +
    " " +
    current_datetime.getFullYear();

  //console.log(formatted_date + timeStamp);
  return formatted_date + timeStamp;
}
