import { Route, BrowserRouter, Routes } from "react-router-dom";

import Login from "../pages/UserEnrollment/Login";

const UnauthenticateAppRoot = () => {
  return (
    <BrowserRouter>
      <main className={"mainDivRouter"}>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
};

export default UnauthenticateAppRoot;
