import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "red",
      textAlign: "center",
      fontWeight: "bold",
    },
  })
);

const DefaultError = () => {
  const classes = useStyles();
  return (
    <p className={classes.root}>
      SOMETHING WENT WRONG. PLEASE REFRESH THE PAGE. IF ERRORS PERSIST CONTACT
      SYSTEM ADMINISTRATOR
    </p>
  );
};

export default DefaultError;
