import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserInterface {
  AccountType: string;
  CanChangePassword: boolean;
  CanEditProfile: boolean;
  DisplayName: any;
  Email: any;
  ExternalPasswordChangeUrl?: string;
  ExternalProfileUrl?: string;
  FirstName: string;
  HasDevices: boolean;
  HasExternalPasswordChangeUrl: boolean;
  HasExternalProfileUrl: boolean;
  HasJobDescription: boolean;
  HasStudentContactInfo: boolean;
  HasStudentDevices: boolean;
  HasStudentInfo: boolean;
  JobDescription: string;
  LastName: string;
  MyDeviceCount: number;
  ObjectID: string;
  PhotoUrl: any;
  StudentContactCount: number;
  StudentCount: number;
  StudentDeviceCount: number;
  StudentIds: any;
  UserPrincipalName: string;
  dcpsAUPAcceptanceDate: string;
  dcpsID: string;
  dcpsType: string;
  extranetGUID: string;
}

interface userState {
  user: UserInterface;
}
const initialState: userState = {
  user: {
    AccountType: "",
    CanChangePassword: false,
    CanEditProfile: false,
    DisplayName: "",
    Email: "",
    ExternalPasswordChangeUrl: "",
    ExternalProfileUrl: "",
    FirstName: "",
    HasDevices: false,
    HasExternalPasswordChangeUrl: false,
    HasExternalProfileUrl: false,
    HasJobDescription: false,
    HasStudentContactInfo: false,
    HasStudentDevices: false,
    HasStudentInfo: false,
    JobDescription: "",
    LastName: "",
    MyDeviceCount: 0,
    ObjectID: "",
    PhotoUrl: "",
    StudentContactCount: 0,
    StudentCount: 0,
    StudentDeviceCount: 0,
    StudentIds: [],
    UserPrincipalName: "",
    dcpsAUPAcceptanceDate: "",
    dcpsID: "",
    dcpsType: "",
    extranetGUID: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<any>) {
      console.log("userpayload", action);
      Object.assign(state, {
        user: action.payload.user,
      });
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
