import { useState, useEffect } from "react";
import classes from "./PartnerManager.module.css";
import {
  DefaultButton,
  PrimaryButton,
  IButtonProps,
} from "@fluentui/react/lib/Button";
import { Icon } from "@fluentui/react/lib/Icon";

import { ChoiceGroup, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";
import { Panel } from "@fluentui/react/lib/Panel";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";

import { getMembers, updateMembersOrganization } from "../../services/POMAdmin";
import { Checkbox } from "@mui/material";

import Error from "../Exceptions/TokenError";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

import defaultStore from '../../util/Globale';

export interface IMembership {
  key: string;
  displayName: string;
  email: string;
  id: string;
  studentNumber: string;
  phone: string;
}

export interface IDetailsListMembershipsProps {
  currentOrg: any;
  isOrgAdmin: boolean;
  isGlobaleAdmin: boolean;
  token: string;
}

const Members = (props: IDetailsListMembershipsProps) => {
  // let _allMembersItems: any;
  let _memberSearchCount = 0;
  let _membersArr: any;

  let errText: string = "";

  const token = useSelector((state: RootState) => state.token.token);

  const [membersItems, setMembersItems] = useState<IMembership[]>([]);

  const [isMemberPanelOpen, setIsMemberPanelOpen] = useState<boolean>(false);
  const [isDeleteMembersDisabled, setIsDeleteMembersDisabled] =
    useState<boolean>(true);

  const [isAddMembersDisabled, setIsAddMembersDisabled] = useState(false);

  const [member, setMember] = useState<any>({});
  const [memberError, setMemberError] = useState("");

  const [foundMembers, setFoundMembers] = useState<any>([]);

  const [showLoading, setShowLoading] = useState(true);

  const [showMemberSearchMessage, setShowMemberSearchMessage] = useState(false);

  const [hideMemberDeleteDialog, setHideMemberDeleteDialog] = useState(true);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [allMembersItems, setAllMembersItems] = useState<any>([]);
  const [selectedKey, setSelectedKey] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState("");
  const [selectedMemberDelete, setSelectedMemberDelete] = useState<any>({});
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (props.currentOrg) {
      getMembers(props.currentOrg.ID, token)
        .then((response) => {
          // console.log("response members CDM", response.Members.length);

          setMembersItems(_generateMembershipMembers(response.Members));
          setShowLoading(false);
          setIsDeleteMembersDisabled(true);
          setSelectedKey(false);

          setAllMembersItems(_generateMembershipMembers(response.Members));
        })
        .catch((err: any) => {
          errText = err;
          setError(true);
        });
    }
  }, [props.currentOrg]);

  const _generateMembershipMembers = (members: any) => {
    let membersOrganized: IMembership[] = [];
    // console.log(members);
    members.map((member: any, i: any) => {
      return membersOrganized.push({
        key: i.toString(),
        displayName: member.DisplayName,
        email: member.Email,
        id: member.ID,
        phone: member.MobilePhone,
        studentNumber: member.Properties.StudentCount,
      });
    });

    console.log("organized", membersOrganized);
    return membersOrganized;
  };

  const onclickHandler = () => {
    setIsMemberPanelOpen(true);
    setFoundMembers([]);
    setMember({});
  };
  const _membersItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: onclickHandler,
      disabled: isAddMembersDisabled || !props.isOrgAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => setHideMemberDeleteDialog(false),
      iconProps: { iconName: "Delete" },
      disabled: isDeleteMembersDisabled || !props.isOrgAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
  ];

  const _farItems: ICommandBarItemProps[] = [
    {
      key: "tile",
      onRender: () => (
        <SearchBox
          placeholder="Filter by mentor name"
          className="searchBox"
          onSearch={(newValue) => _onSearchChange(newValue)}
          onChange={(ev) => _onSearchChange(ev?.target?.value)}
        />
      ),
    },
  ];

  const overflowProps: IButtonProps = {
    ariaLabel: "More commands",
    menuIconProps: {
      iconName: "ChevronDown",
    },
    styles: {
      menuIcon: {
        fontSize: "20px",
        color: "#0078d4",
      },
    },
  };

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  const dialogContentProps = {
    type: DialogType.close,
    title: "Delete Mentor",
    subText: "Are you sure you want to delete this mentor?",
    style: { title: { color: "red" }, content: { color: "black" } },
  };

  const columnSort = (column: any) => {
    //alert("clicked");
    let obj = [...membersItems];

    if (sortAsc) {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? 1 : -1;
      });
    } else {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? -1 : 1;
      });
    }

    setMembersItems([...obj]);

    setSortAsc(!sortAsc);
  };

  const onChangeCheckbox = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    index: any,
    request: any
  ) => {
    setSelectedKey(checked);
    setCheckboxValue(ev.target.value);

    if (checked) {
      setIsDeleteMembersDisabled(false);
      setSelectedMemberDelete(request);
    } else {
      setIsDeleteMembersDisabled(true);
    }
  };
  const _onSearchChange = (text: any): void => {
    setMembersItems(
      !!text
        ? allMembersItems.filter(
            (i: any) =>
              i.displayName.toLowerCase().indexOf(text.toLocaleLowerCase()) > -1
          )
        : allMembersItems
    );
  };

  const _onRenderMemberFooterContent = (): JSX.Element => {
    let _r = (
      <div>
        <PrimaryButton
          onClick={submitMember}
          style={{ marginRight: "8px" }}
          disabled={Object.keys(member).length === 0}
        >
          Save Mentor
        </PrimaryButton>
        <DefaultButton
          onClick={() => setIsMemberPanelOpen(false)}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const validateUser = async (user: string) => {
    const response = await fetch(
      defaultStore.webAPIMainEndPoint + "/api/OutReach/people?find=" +
        user,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json;odata=verbose",
          Authorization: "Bearer " + token,
          "X-app-location": window.location.origin + window.location.pathname,
        },
      }
    );

    const Users = await response.json();

    // console.log(UserInfo);

    return Users;
  };

  const onMemberChange = (event: any): void => {
    // console.log(event);
    //console.log(event.target.value);

    if (
      event.target.value.length === 3 ||
      event.target.value.length === 5 ||
      event.target.value.length === 7
    ) {
      _memberSearchCount++;
      let thisCount = _memberSearchCount;

      setShowMemberSearchMessage(true);

      validateUser(event.target.value).then((Users) => {
        // console.log("Validated ...");
        // console.log(Users);

        if (Users["ErrorMessage"]) {
          setMemberError(Users["ErrorMessage"]);
        } else if (Users["ExceptionMessage"]) {
          setMemberError(Users["ExceptionMessage"]);
        }

        if (thisCount === _memberSearchCount) {
          if (Users.length > 1) {
            let _members: any[] = [];

            for (var i = 0; i < Users.length; i++) {
              let _membersTmp = {
                key: "",
                text: "",
              };

              _membersTmp["key"] = Users[i]["ID"];
              _membersTmp["text"] =
                Users[i]["DisplayName"] + " (" + Users[i]["Email"] + ")";

              _members.push(_membersTmp);
            }

            //console.log(_members);

            setShowMemberSearchMessage(false);
            setFoundMembers(_members);

            if (event.target.value.length > 0) {
              setMemberError("");
            } else {
              setMemberError("Enter a member");
            }
          }
        }
      });
    }
  };

  const submitMember = () => {
    _membersArr = [];
    membersItems.map((item: any) => {
      return _membersArr.push(item.id);
    });

    _membersArr.push(member.key);

    //console.log(this._membersArr);

    setShowLoading(true);
    setMember({});
    setIsMemberPanelOpen(false);

    updateMembersOrganization(props.currentOrg.ID, _membersArr, token).then(
      () => {
        getMembers(props.currentOrg.ID, token).then((_r) => {
          // let _mX = this._generateMembershipMembers(_r);

          setMembersItems(_generateMembershipMembers(_r.Members));

          setShowLoading(false);
        });
      }
    );
  };

  const deleteMember = () => {
    _membersArr = [];

    membersItems.map((item: any) => {
      return _membersArr.push(item.id);
    });

    let index = _membersArr.indexOf(selectedMemberDelete.id);

    if (index > -1) {
      _membersArr.splice(index, 1);
    }

    console.log("after filter", _membersArr);

    setShowLoading(true);
    setHideMemberDeleteDialog(true);
    updateMembersOrganization(props.currentOrg.ID, _membersArr, token).then(
      () => {
        getMembers(props.currentOrg.ID, token).then((_r) => {
          // console.log(_r);

          setMembersItems(_generateMembershipMembers(_r.Members));

          setShowLoading(false);
          setSelectedKey(false);
          setIsDeleteMembersDisabled(true);
        });
      }
    );
  };

  return (
    <>
      <div style={{ margin: "1%" }}>
        <p style={{ fontSize: "20px", color: "#006cb9" }}>
          Mentors: {props.currentOrg.DisplayName}
        </p>
        <CommandBar
          items={_membersItems}
          farItems={_farItems}
          overflowButtonProps={overflowProps}
          ariaLabel="Use left and right arrow keys to navigate between commands"
        />
        <Table className={classes.tableList}>
          <Thead style={{ textAlign: "left" }}>
            <Tr>
              <Th>Select</Th>
              <Th
                style={{ cursor: "pointer" }}
                onClick={() => columnSort("displayName")}
              >
                Mentor Name{" "}
                <Icon
                  iconName="Sort"
                  className={classes.iconSmallScreen}
                ></Icon>
              </Th>
              <Th
                style={{ cursor: "pointer" }}
                onClick={() => columnSort("email")}
              >
                Email{" "}
                <Icon
                  iconName="Sort"
                  className={classes.iconSmallScreen}
                ></Icon>
              </Th>
              <Th
                style={{ cursor: "pointer" }}
                onClick={() => columnSort("phone")}
              >
                Phone{" "}
                <Icon
                  iconName="Sort"
                  className={classes.iconSmallScreen}
                ></Icon>
              </Th>
              <Th
                style={{ cursor: "pointer" }}
                onClick={() => columnSort("studentNumber")}
              >
                Student #{" "}
                <Icon
                  iconName="Sort"
                  className={classes.iconSmallScreen}
                ></Icon>
              </Th>
            </Tr>
          </Thead>

          {!showLoading && (
            membersItems.length > 0 && (
              membersItems.map((request: any, i: any) => {
                //console.log(i);
                return (
                  <Tbody key={request.key}>
                    <Tr key={request.key}>
                      <Td>
                        <Checkbox
                          value={i}
                          className={classes.checkBox}
                          checked={
                            i.toString() === checkboxValue ? selectedKey : false
                          }
                          onChange={(e, checked) => {
                            onChangeCheckbox(e, checked, i, request);
                          }}
                        />
                      </Td>
                      <Td>{request.displayName}</Td>
                      <Td>{request.email}</Td>
                      <Td>{request.phone}</Td>
                      <Td>{request.studentNumber}</Td>
                    </Tr>
                  </Tbody>
                );
              })
            )
          )}
        </Table>
        {showLoading && (
          <div className={classes.spinnerPos}>
              <Spinner size={SpinnerSize.large}></Spinner>
          </div>)
        }
        {!showLoading && membersItems.length == 0 && (
            <div className={classes.spinnerPos}>
              <b>List Empty</b>
            </div>)
        }
        {error && <Error text={errText} customText="Not able to load" />}
      </div>
      <Panel
        headerText={"New Mentor"}
        isOpen={isMemberPanelOpen}
        onDismiss={() => setIsMemberPanelOpen(false)}
        closeButtonAriaLabel="Close"
        isFooterAtBottom={false}
        onRenderFooterContent={_onRenderMemberFooterContent}
      >
        <br />
        <TextField
          label={"Mentor Name"}
          placeholder="Enter mentor name"
          errorMessage={memberError}
          onChange={onMemberChange}
          required
        ></TextField>

        {showMemberSearchMessage ? (
          <Spinner
            style={{ fontSize: "16px", padding: "10px" }}
            size={SpinnerSize.large}
          ></Spinner>
        ) : (
          ""
        )}
        <ChoiceGroup
          options={foundMembers}
          required={true}
          onChange={(ev, option: any) => setMember(option)}
        />
      </Panel>

      <Dialog
        hidden={hideMemberDeleteDialog}
        onDismiss={() => setHideMemberDeleteDialog(true)}
        modalProps={modelProps}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={deleteMember}
            text="Delete"
            style={{
              backgroundColor: "red",
            }}
          />
          <DefaultButton
            onClick={() => setHideMemberDeleteDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default Members;
