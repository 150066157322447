import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import UnauthenticateAppRoot from "./routes/UnauthenticateAppRoot";
import { CircularProgress } from "@mui/material";
import AppRoot from "./routes/AppRoot";
import { useSelector } from "react-redux";
import { RootState } from "./store";
//import { useState, useEffect } from "react";

import { OneViewFull } from "./img/Icons";

const _loading = (
  <div className={"loadingDiv "}>
    <div className={"authenticateDiv"}>
      <div className={"headerTitle"}>
        <OneViewFull style={{ width: "100%" }} />
      </div>

      <div>Authenticating...</div>

      <div style={{ padding: 20 }}>
        <CircularProgress style={{ color: "rgb(0, 120, 212)" }} />
      </div>
    </div>
  </div>
);
const Token = () => {
  const token = useSelector((state: RootState) => state.token.token);
  const tokenExp = useSelector(
    (state: RootState) => state.token.tokenExpiration
  );

 // console.log(tokenExp > new Date().toLocaleString());

  return (
    <>
      <AuthenticatedTemplate>
        {/* {token && <AppRoot />}  */}
        {token ? <AppRoot /> : _loading}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticateAppRoot />
      </UnauthenticatedTemplate>
    </>
  );
};

export default Token;
