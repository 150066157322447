import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMsal} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { initializeIcons } from "@fluentui/react/lib/Icons"; //TODO: Delete
import { useIsAuthenticated } from "@azure/msal-react";

import defaultStore from "./util/Globale";
// import { getUserProfileInfo } from "./services/ContactInfo";
import { userActions } from "./store/user-slice";
import { tokenActions } from "./store/token-slice";
import { registerError } from "./store/error-slice";
import { useSelector } from "react-redux";
import { RootState } from "./store";

import "./style/App.css";

import Token from "./Token";

// Remove comments from Prod
if (defaultStore.isProduction) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

initializeIcons();
/*
 *
 *  Authentication
 *
 */

const loginConfig = {
  scopes: [
    "openid",
    defaultStore.scope,
    "user.readbasic.all",
  ],
  extraQueryParameters: { domain_hint: "duvalschools.org", prompt: "" },
};

function App() {
  const { instance, accounts, inProgress } = useMsal();

  const dispatch = useDispatch();

  const isAuthenticated = useIsAuthenticated();
  // console.log("msal instance", instance);
  // console.log("account", accounts);
  // console.log("isauthenticated", isAuthenticated);
  // console.log("isProgress", inProgress);

  const tokenExp = useSelector(
    (state: RootState) => state.token.tokenExpiration
  );

  const request = {
    ...loginConfig,
    account: accounts[0],
  };

  useEffect(() => {
    if (isAuthenticated && inProgress === "none" && accounts.length !== 0) {
      RequestAccessToken();
    }
  }, [inProgress, accounts, instance, isAuthenticated]);

  const APIsToConnect = (res: any) => {
    let payload = {
      token: res.accessToken,
      expiration: new Date(res.expiresOn).toLocaleString(),
    };
    dispatch(tokenActions.updateToken(payload));
    // getUserProfileInfo(res.accessToken).then((result: any) => {
    //   if (result.status === 200) {
    //     let payload = {
    //       user: result.data,
    //     };
    //     dispatch(userActions.updateUser(payload));
    //   } else {
    //     registerError(dispatch, result, "user", true);
    //   }
    // });
  }

  function RequestAccessToken() {
    //call to get token
    instance
      .acquireTokenSilent(request)
      .then((res) => {
        console.log(res);
        APIsToConnect(res);
      })
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request);
        }
      });
  }
  return (
    <React.Fragment>
      <Token />
    </React.Fragment>
  );
}

export default App;
