import { Panel, PanelType } from "@fluentui/react/lib/Panel";

import {
  IconButton,
  DefaultButton,
  PrimaryButton,
} from "@fluentui/react/lib/Button";

import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";

import { Checkbox, Modal } from "@fluentui/react";

import { Label } from "@fluentui/react/lib/Label";

import MyRequests from "../../components/ferpa/MyRequests";
import MyStudents from "../../components/ferpa/MyStudents";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

import {
  getMyStudentsList,
  getProgramsList,
  getMyRequestsList,
  postMyRequestsList,
} from "../../services/FERPA";
import axios, { AxiosResponse, AxiosError } from "axios";

import classes from "../../components/ferpa/FERPA.module.css";
import { useEffect, useState } from "react";

const PomParents = () => {
  // Change tab title
  document.title = "FERPA";
  const token = useSelector((state: RootState) => state.token.token);

  const [showSubmitRequestModalParent, setShowSubmitRequestModalParent] =
    useState(false);
  const [showAddRequestPanel, setShowAddRequestPanel] = useState(false);

  const [studentsList, setStudentsList] = useState<any>();
  const [requestsList, setRequestsList] = useState("");

  const [isMyRequestsLoaded, setIsMyRequestsLoaded] = useState(false);
  const [isMyStudentsLoaded, setIsMyStudentsLoaded] = useState(false);

  const [selectedProgramKey, setSelectedProgramKey] = useState<any>();
  const [selectedStudentKey, setSelectedStudentKey] = useState<any>();

  const [studentOptions, setStudentOptions] = useState<any>([]);
  const [programOptions, setProgramOptions] = useState<any>([]);

  const [selectedProgramName, setSelectedProgramName] = useState("");
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [selectedStudentNumber, setSelectedStudentNumber] = useState("");
  const [selectedStudentDOB, setSelectedStudentDOB] = useState("");
  const [selectedLegaGuardian, setSelectedLegaGuardian] = useState("");

  const [programsOptionValueError, setProgramsOptionValueError] = useState("");
  const [studentsOptionValueError, setStudentsOptionValueError] = useState("");

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [cancelDisabled, setCancelDisabled] = useState(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);

  const [AddFormSubmitDisabled, setAddFormSubmitDisabled] = useState(true);

  useEffect(() => {
    //console.log("request list update");
    getMyStudentsListProc(token);
    getProgramsListProc(token);
    getMyRequestsListProc(token);
  }, []);

  const submitRequestForm = (): void => {
    var _sendOk = true;

    if (_sendOk === true) {
      addRequest();
    }
  };

  const addRequest = async () => {
    setShowLoadingMessage(true);
    setSubmitDisabled(true);
    setCancelDisabled(true);

    var _o = {
      Program: selectedProgramKey.key,
      Student: selectedStudentKey.key,
    };

    await postMyRequestsList(token, _o);

    setShowLoadingMessage(false);

    //setShowSubmitRequestModalParent(false);
    window.location.reload();
  };

  const OnchangeUserAgreed = (ev: any, checked: any): void => {
    let isChecked: boolean = false;

    if (checked) {
      isChecked = true;
    }

    if (isChecked) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const closeSubmitRequestModel = () => {
    setShowSubmitRequestModalParent(false);
  };

  //to open side panel when we click on sumit a parent FERPA consent form
  const openFERPAPanel = () => {
    setSubmitDisabled(true);
    setShowAddRequestPanel(true);
  };

  const _generateProgramsListing = (seedItems: any) => {
    const options: IDropdownOption[] = [];

    var Programs = JSON.parse(seedItems.toString());

    if (Programs) {
      for (let i = 0; i < Programs.length; i++) {
        options.push({
          key: Programs[i]["ID"],
          text: Programs[i]["DisplayName"],
        });
      }
    }

    return options;
  };

  const _generateStudentsListing = (seedItems: any) => {
    const options: IDropdownOption[] = [];

    var Students = JSON.parse(seedItems.toString());

    if (Students) {
      for (let i = 0; i < Students.length; i++) {
        options.push({
          key: Students[i]["ID"],
          text: Students[i]["DisplayName"],
        });
      }
    }

    return options;
  };

  /// side panelform drop dowm onchange functions

  const OnChangeProgramOption = (ev: any, item: any) => {
    setSelectedProgramKey(item);
    setSelectedProgramName(item.text);

    if (item) {
      setProgramsOptionValueError("");

      if (selectedStudentName !== "") {
        setAddFormSubmitDisabled(false);
      }
    } else {
      setProgramsOptionValueError("Select a program");
    }
  };

  const OnChangeStudentOption = (ev: any, item: any) => {
    setSelectedStudentKey(item);
    if (!item) {
      setStudentsOptionValueError("");
    } else {
      setSelectedStudentKey(item);
      setSelectedStudentName(item.text);
      setSelectedStudentNumber(item.key.toString());

      if (selectedProgramName !== "") {
        setAddFormSubmitDisabled(false);
      }

      let _email = "s" + item.key + "@students.duvalschools.org";
      let _displayName = item.text.replace("(student)", "");
      let _studentInfo = (
        <div>
          {" "}
          {_displayName} - {_email}{" "}
        </div>
      );
    }
  };

  /// end of side panel form drop dowm onchange functions

  //side panel footer section (Request and Cancel)

  const _onRenderAddRequestFooterContent = (): JSX.Element => {
    var _r = <div>Footer</div>;

    _r = (
      <div>
        <PrimaryButton
          onClick={_showAddRequestForm}
          style={{ marginRight: "8px" }}
          disabled={AddFormSubmitDisabled}
        >
          Request
        </PrimaryButton>
        <DefaultButton
          onClick={_hideAddRequestPanel}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </DefaultButton>
      </div>
    );
    return _r;
  };

  const _showAddRequestForm = () => {
    let _s = studentsList;

    for (var i = 0; i < _s.length; i++) {
      //console.log(_s[i]);
      if (_s[i].ID === selectedStudentNumber) {
        let _dob = new Date(_s[i]["DateOfBirth"]);
        let firstKey = Object.keys(_s[i]["Properties"]["GuardianLookup"])[0];

        setSelectedStudentDOB(
          _dob.getMonth() + "/" + _dob.getDay() + "/" + _dob.getFullYear()
        );
        setSelectedLegaGuardian(
          _s[i]["Properties"]["GuardianLookup"][firstKey]["DisplayName"]
        );
        break;
      }
    }

    setShowAddRequestPanel(false);
    setAddFormSubmitDisabled(true);
    setShowSubmitRequestModalParent(true);
  };

  const _hideAddRequestPanel = () => {
    setAddFormSubmitDisabled(true);
    setShowAddRequestPanel(false);
  };

  const getMyRequestsListProc = async (token: string) => {
    const requests = await getMyRequestsList(token);

    //console.log(requests);

    setRequestsList(requests);
    setIsMyRequestsLoaded(true);
  };

  //to get students undert the parent and thier info
  const getMyStudentsListProc = async (token: string) => {
    const students = await getMyStudentsList(token);

    setStudentsList(students);
    setIsMyStudentsLoaded(true);

    setStudentOptions(_generateStudentsListing(JSON.stringify(students)));
  };

  const getProgramsListProc = async (token: string) => {
    const programs = await getProgramsList(token);

    setProgramOptions(_generateProgramsListing(JSON.stringify(programs)));
  };

  const _addRequestPanel = (
    <Panel
      isLightDismiss
      isOpen={showAddRequestPanel}
      onDismiss={_hideAddRequestPanel}
      type={PanelType.custom}
      customWidth="600px"
      headerText={"New Request"}
      isFooterAtBottom={true}
      closeButtonAriaLabel="Close"
      onRenderFooterContent={_onRenderAddRequestFooterContent}
    >
      <span>
        <div>
          Please select a student and a program from the dropdowns below.
        </div>
        <br />

        <Dropdown
          placeholder="Select a Student"
          label="Student"
          options={studentOptions}
          disabled={false}
          required
          className={"DropDown"}
          onChange={OnChangeStudentOption}
          errorMessage={studentsOptionValueError}
          styles={{ dropdown: { width: 300 } }}
        />

        <br />
        <br />

        <Dropdown
          placeholder="Select a Program"
          label="Program"
          options={programOptions}
          disabled={false}
          className={"DropDown"}
          required
          onChange={OnChangeProgramOption}
          errorMessage={programsOptionValueError}
          styles={{ dropdown: { width: 300 } }}
        />
      </span>
    </Panel>
  );

  const _ferpaForm = (
    <Modal
      titleAriaId={"Submit Request"}
      subtitleAriaId={"Submit Request"}
      isOpen={showSubmitRequestModalParent}
      onDismiss={closeSubmitRequestModel}
      isBlocking={false}
      containerClassName={"SubmitRequestContainer"}
      dragOptions={undefined}
      onDismissed={() => setShowSubmitRequestModalParent(false)}
    >
      <div className={"SubmitRequestContainerHeader"}>
        <IconButton
          styles={{
            root: {
              marginLeft: "4px",
              marginTop: "4px",
              marginRight: "2px",
              float: "right",
            },
            rootHovered: {},
          }}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close request form"
          onClick={closeSubmitRequestModel}
        />
      </div>

      <div className={"SubmitRequestContainerBody"}>
        <div>
          <div className={"addRequestContainer"}>
            <div className={"addRequestRow"}>
              <div className={"SubmitRequestContainerBodyHeader"}>
                <h3>PARENT FERPA CONSENT FORM</h3>
              </div>
              <div className={"SubmitRequestContainerBodyText"}>
                <p>
                  {" "}
                  I consent to the disclosure of the following educational
                  records of{" "}
                  <span>
                    <b>{selectedStudentName}</b>
                  </span>{" "}
                  to{" "}
                  <span>
                    <b>{selectedProgramName}</b>
                  </span>{" "}
                  and the Jacksonville Public Education Fund ("Data Recipients")
                  and I understand that the following educational records will
                  be available to facilitate research to improve instruction and
                  student supports throughout Duval County.
                  <br />
                </p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textJustify: "auto",
                    justifySelf: "center",
                  }}
                >
                  <span style={{ padding: 20, textAlign: "center" }}>
                    <b>{selectedStudentName}</b> <br />
                    <b>{selectedStudentNumber}</b> <br />
                    <b>{selectedStudentDOB}</b> <br />
                  </span>
                </div>

                <p>
                  All of the following educational records are approved for
                  release:
                </p>
                <ul>
                  <li>All student and family demographics</li>
                  <li>All academic data</li>
                  <li>All district/state/natlonat student assessments</li>
                  <li>All attendance data</li>
                  <li>All student services data including discipline</li>
                  <li>
                    All data contained on the HomeRoom parent and health
                    portal
                  </li>
                </ul>

                <br />
                <p>
                  Written consent of parents is usually required for the release
                  of personally identifiable records or other information
                  protected by the Family Education and Privacy Act to agencies
                  or individuals. Agencies or individuas may not share
                  information with any other party without the wrltten consent
                  of the parents unless entitled to do so under FERPA 99.33. You
                  may révoke this authorization at any time. Unless revoked
                  earlier, this consent will remain in effect until one school
                  year (August 1 st to July 31 st of the following year) after
                  program enrollment.
                </p>
              </div>
            </div>
            <div className={"addRequestRow"}>
              <Label className={"AgreeLabel"}>
                <Checkbox
                  onChange={(ev, checked) => {
                    OnchangeUserAgreed(ev, checked);
                  }}
                  className={"AgreeCheckbox"}
                />
                I agree to the terms and services described above.
              </Label>
              <br />
            </div>
            <div style={{ marginLeft: 30 }}>
              {" "}
              <b>{selectedLegaGuardian}</b>
            </div>

            <div>
              {showLoadingMessage ? (
                <Spinner
                  style={{ height: "100%", fontSize: "20px" }}
                  label="updating..."
                  size={SpinnerSize.large}
                ></Spinner>
              ) : (
                ""
              )}
            </div>

            <div
              className={"addRequestRow"}
              style={{ justifyContent: "center", padding: 5 }}
            >
              <PrimaryButton
                ariaDescription="Submit form"
                onClick={submitRequestForm}
                className={"submitButton"}
                disabled={submitDisabled}
              >
                Submit
              </PrimaryButton>
              <DefaultButton
                disabled={cancelDisabled}
                ariaDescription="Cancel"
                onClick={closeSubmitRequestModel}
                className={"cancelButton"}
              >
                Cancel
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <div id="homeroom-stream">
      <div className={"pomContainer"}>
        <div className={"pomRow"}>
          <div style={{ textAlign: "center" }}>
            <PrimaryButton
              onClick={openFERPAPanel}
              style={{ margin: 20, padding: 20 }}
            >
              Submit a Parent FERPA Consent Form
            </PrimaryButton>
          </div>
          <div
            style={{
              textAlign: "justify",

              margin: "10px",
            }}
          >
            Forging meaningful partnerships between schools and community
            organizations is one of our top priorities. These partnerships are
            essential to expand when, where, what and how students learn.
            Everyone benefits when schools and community organizations work
            together to support learning. With the permission of the legal
            guardian, community partners can access student-level demographic,
            discipline and academic data. They use data to track and strengthen
            student performance and demonstrate the impact of their program. If
            you agree to allow our Community Partners to support your student's
            progress, select the blue Parent FERPA Consent Form. You will need
            to submit multiple forms if your child participates in more than one
            program.
          </div>

          {_addRequestPanel}
          {_ferpaForm}

          <div className={"pomRowMain"}>
            {isMyStudentsLoaded ? (
              <MyStudents>{studentsList}</MyStudents>
            ) : (
              <Spinner
                style={{ height: "100%", fontSize: "14px" }}
                label="Loading students..."
                size={SpinnerSize.large}
              ></Spinner>
            )}

            {isMyRequestsLoaded ? (
              <MyRequests>{requestsList}</MyRequests>
            ) : (
              <div className={classes.spinner}>
                <Spinner
                  style={{ height: "100%", fontSize: "14px" }}
                  label="Loading requests..."
                  size={SpinnerSize.large}
                ></Spinner>
              </div>
            )}
          </div>
        </div>

        <div className={classes.footerVersion}>POM Parents V1.0.2.64</div>
      </div>
    </div>
  );
};

export default PomParents;
