import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//TODO: add error types and integrate into catch() statements

interface ErrorInterface {
  fatal: boolean,
  message: string,
  status: number,
  errorType: string
}

interface ErrorState {
  error: ErrorInterface;
}

const initialState: ErrorState = {
  error: {
    fatal: false,
    message: "",
    status: 0,
    errorType:""

  },
};
const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    updateError(state, action: PayloadAction<ErrorInterface>) {
      console.log("payload menu", action);
      return Object.assign({}, state, { error: action.payload });
    },
  },
});

export const errorActions = errorSlice.actions;

export function registerError(dispatch:any, e:any, type: string, fatal:boolean){
  console.log(e);
  if(e){
    console.error(e);
    dispatch(errorActions.updateError({message: e.statusText, status: e.status, errorType: type, fatal:fatal}));
  }
}

export default errorSlice;
