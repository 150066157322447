import { useState, useEffect } from "react";

import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { Tooltip } from "@mui/material";
import {
  Spinner,
  SpinnerSize,
  IIconProps,
  Modal,
  Panel,
  Label,
  TextField,
} from "@fluentui/react";
import { ContextualMenu } from "@fluentui/react/lib/ContextualMenu";

import { FontIcon, Icon } from "@fluentui/react/lib/Icon";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import {
  CommandBarButton,
  DefaultButton,
  PrimaryButton,
  IconButton,
} from "@fluentui/react/lib/Button";
import Information from "./Information";
import StudentsProgramsRequests from "./StudentsProgramsRequests";
import Members from "./Members";
import Administrators from "./Administrators";
import StudentsAndAssociations from "./StudentsAndAssociations";
import Programs from "./Programs";
import AffiliatedPrograms from "./AffiliatedPrograms";
import Documents from "./Documents";

import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Select from "react-select";

import {
  getUserAppRoles,
  getInfo,
  fetchOrgsList,
  getPrograms,
  deleteOrganization,
  addOrganization,
} from "../../services/POMAdmin";

import classes from "./PartnerManager.module.css";
import Error from "../Exceptions/TokenError";

require("../../style/PomAdmin.scss");

export interface IAdminMenuButtonProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

export interface IOrgListItem {
  key: number;
  DisplayName: string;
  value: number;
}

const pivotStyles = {
  root: {
    whiteSpace: "normal",
  },
};

const addIcon: IIconProps = { iconName: "Add" };
const deleteIcon: IIconProps = { iconName: "Delete" };
const manageProgramsIcon: IIconProps = { iconName: "EntitlementPolicy" };

const POMAdmin = () => {
  // Change tab title
  document.title = "Partner Manager";
  let orgsDropdownOptionsTemp: any = [];
  const token = useSelector((state: RootState) => state.token.token);

  const [isProgramsLoaded, setIsProgramsLoaded] = useState<boolean>(false);

  const [isAddOrgPanelOpen, setIsAddOrgPanelOpen] = useState<boolean>(false);
  const [newOrgName, setNewOrgName] = useState<string>("");
  const [newOrgNameError, setNewOrgNameError] = useState<string>("");
  const [currentOrgLoadingError, setCurrentOrgLoadingError] =
    useState<boolean>(false);
  const [currentOrgLoadingErrorMsg, setCurrentOrgLoadingErrorMsg] =
    useState<string>("");
  const [hideDeleteDialog, setHideDeleteDialog] = useState<boolean>(true);
  const [showProgramsModal, setShowProgramsModal] = useState<boolean>(false);

  const [orgPanelExpended, setOrgPanelExpended] = useState<boolean>(false);

  const [orgPanelMessage, setOrgPanelMessage] = useState<any>(null);

  const [isGlobalAdmin, setIsGlobalAdmin] = useState<boolean>(false);
  const [isOrgAdmin, setIsOrgAdmin] = useState<boolean>(false);

  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [accessMessage, setAccessMessage] = useState<any>(null);
  const [currentOrg, setCurrentOrg] = useState<any>();
  const [currentPrograms, setCurrentPrograms] = useState<string>("");

  const [error, setError] = useState<boolean>(false);

  const [orgsDropdownOptions, setOrgsDropdownOptions] = useState<any>([]);

  useEffect(() => {
    getUserAppRoles(token)
      .then((appRoleInfo) => {
        // console.log("logged in user's info", appRoleInfo);

        if (appRoleInfo.IsGlobalAdmin === true) {
          setIsGlobalAdmin(true);
          setIsOrgAdmin(true);
        }

        if (appRoleInfo.AdminOf.length > 0) {
          setIsOrgAdmin(true);
        }
        if (appRoleInfo.IsValid) {
          setHasAccess(true);
          //to get all orgnizationslist in parner manager
          getOrgsList();
        } else {
          setHasAccess(false);
          setAccessMessage(
            <div>Not a valid user. Contact your administrator.</div>
          );
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  useEffect(()=>{
      if(orgsDropdownOptions.length==0){
        setCurrentOrgLoadingErrorMsg(
          "No organaization associated with your account"
        );
        setCurrentOrgLoadingError(true);
        setCurrentOrg(null);
      }
      else{
        setCurrentOrgLoadingError(false);
      }
    },[orgsDropdownOptions]);

  const updateDropdown = (id:string, newValue:string, newLabel:string, newImage:string) => {
    // In case the user changes the name of the org, update the frontend state object to mirror backend changes
    orgsDropdownOptions.map((elem:any, idx:any) => {
      if(elem.value == id){
        let orgsDropdownOptionsTmp = orgsDropdownOptions
        if(!newValue)
          newValue = orgsDropdownOptionsTmp[idx].value;
        if(!newLabel)
          newLabel = orgsDropdownOptionsTmp[idx].label;
        if(!newImage)
          newImage = orgsDropdownOptionsTmp[idx].image;
        orgsDropdownOptionsTmp.splice(idx, 1);
        setOrgsDropdownOptions([...orgsDropdownOptionsTmp, {value: newValue, label:newLabel, image:newImage}]);
      }
    })
  }

  const handleInfoChange = (newName:string) => {
    updateDropdown(currentOrg.ID, "", newName, "");
    setCurrentOrg({...currentOrg, DisplayName: newName});
  }

  const handleProgramChange = (id:string, newName:string, newType:string) => {
    // Update program list state object so front end displays assosiated backend changes
    let currentProgramsTmp = JSON.parse(currentPrograms.toString());
      currentProgramsTmp.Programs.map((elem:any, idx:any) => {
        if(elem.ID == id){
          currentProgramsTmp.Programs[idx] = {
            ...currentProgramsTmp.Programs[idx],
            DisplayName: newName,
            Properties: {
              Type: newType,
            },
          };
          setCurrentPrograms(JSON.stringify(currentProgramsTmp)); 
        }
      });
  }

  let styles = {
    option: (provided: any, state: any) => ({
      ...provided,
      padding: "20px 20px 20px 50px",
      marginLeft: "5px",
      marginright: "5px",
      backgroundImage: "url(" + state.data.image + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "40px 35px",
      backgroundPosition: "left",
      borderRadius: "5px",
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };

  let _dragOptions = {
    moveMenuItemText: "Move",
    closeMenuItemText: "Close",
    menu: ContextualMenu,
  };

  const handlerClick = () => {
    setShowProgramsModal(true);
  };

  const deleteOrg = () => {
    //  console.log(this.state.currentOrg);

    var _orgIdTmp = currentOrg.ID;

    deleteOrganization(_orgIdTmp, token).then((orgInfo) => {
      //org listing
      //console.log(orgInfo);
      setHideDeleteDialog(true);
      //refesh orgs

      if (orgInfo["ErrorMessage"]) {
        alert(orgInfo["ErrorMessage"]);
      } else {
        getOrgsList();
      }
    });
  };

  //add new org save and cancel buttons on panel
  const _onRenderFooterContent = (): JSX.Element => {
    var _r = <div>Footer</div>;
    _r = (
      <div>
        <PrimaryButton
          onClick={submitOrganization}
          style={{ marginRight: "8px" }}
        >
          Save
        </PrimaryButton>
        <DefaultButton
          onClick={() => setIsAddOrgPanelOpen(false)}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const submitOrganization = () => {
    //alert("Submit Org");

    if (newOrgName === "") {
      setNewOrgNameError("Enter organization name");
      return false;
    } else {
      let _m = (
        <Spinner
          style={{ height: "100%", fontSize: "14px" }}
          label="Adding organization."
          size={SpinnerSize.xSmall}
        ></Spinner>
      );
      setOrgPanelMessage(_m);

      addOrganization(newOrgName, token).then((orgInfo) => {
        //org listing
        // console.log(orgInfo);

        if (orgInfo["ErrorMessage"]) {
          let _m = (
            <div style={{ color: "red", fontSize: "18px" }}>
              <Icon iconName="Error"></Icon> {orgInfo["ErrorMessage"]}
            </div>
          );
          setOrgPanelMessage(_m);
        } else {
          setOrgPanelMessage("");
          setIsAddOrgPanelOpen(false);
          getOrgsList();
        }

        //refesh orgs
      });
    }
  };
  // adding new org to the org list
  const onOrgNameChange = (event: any): void => {
    setNewOrgName(event.target.value);
    if (event.target.value.length > 0) {
      setNewOrgNameError("");
    } else {
      setNewOrgNameError("Enter organization name");
    }
  };

  const _showProgramsModal = (): void => {
    setShowProgramsModal(true);

    getPrograms(token).then((orgInfo) => {
      var _obj = JSON.stringify(orgInfo);

      // console.log("get programs result...");
      // console.log(_obj);

      setCurrentPrograms(_obj);
      setShowProgramsModal(true);
      setIsProgramsLoaded(true);
    });
  };

  const onSelection = (e: any) => {
    // console.log("onchange orgId from drop down", e.value);
    initOrg(e.value);
  };
  // getting orgs list
  const getOrgsList = async () => {
    let org_obj = await fetchOrgsList(token);

    // console.log(org_obj);

    org_obj.map((org: any) => {
      let item = {
        value: org.ID,
        label: org.DisplayName,
        image: org.HasLogo ? org.LogoURL : "",
      };

      orgsDropdownOptionsTemp.push(item);
    });

    setOrgsDropdownOptions(orgsDropdownOptionsTemp);

    console.log("drop down options", orgsDropdownOptionsTemp);

    //console.log("orgs list", this.state.orgsList);

    if (!currentOrg) {
      // console.log("calling initOrg ...");
      //console.log(org_obj.length);
      if (org_obj.length > 0) {
        initOrg(org_obj[0].ID);
      } else {
        //alert("No organaization associated with your account");
        // console.log(org_obj.length);
        setCurrentOrgLoadingErrorMsg(
          "No organaization associated with your account"
        );
        setCurrentOrgLoadingError(true);
      }
    }
  };

  const initOrg = (orgObjID: any) => {
    //getting each org's info to present under information tab form
    getInfo(orgObjID, token).then((orgInfo) => {
      //console.log(" >> initial orgInfo >> ");
      //console.log(orgInfo);
      setCurrentOrg(orgInfo);

      // console.log("current org", this.state.currentOrg);
    });
  };

  const dismissHandler = () => {
    setNewOrgName("");
    setIsAddOrgPanelOpen(false);
  };
  const _menu = (
    <div style={{ padding: "10px", display: "block" }}>
      <CommandBarButton
        iconProps={addIcon}
        text="New"
        disabled={!isGlobalAdmin}
        onClick={() => setIsAddOrgPanelOpen(true)}
        checked={true}
        style={{
          backgroundColor: "transparent",
          margin: "5px",
        }}
      />
      <CommandBarButton
        iconProps={deleteIcon}
        text="Delete"
        disabled={!isGlobalAdmin}
        onClick={() => setHideDeleteDialog(false)}
        checked={true}
        style={{
          backgroundColor: "transparent",
          margin: "5px",
        }}
      />
      <CommandBarButton
        iconProps={manageProgramsIcon}
        text="Programs"
        disabled={!isOrgAdmin}
        onClick={_showProgramsModal}
        checked={true}
        style={{
          backgroundColor: "transparent",
          margin: "5px",
        }}
      />
    </div>
  );
  return (
    <>
      {hasAccess ? (
        <div className={classes.wrapPOM}>
          <h1 style={{ textAlign: "center" }}>Partner Manager</h1>
          {hasAccess ? (
            <div
              style={{
                margin: "10px auto",
                padding: "20px",
              }}
            >
              <h3>Select Organization Name</h3>

              <div style={{ display: "flex" }}>
                <Select
                  className={classes.dropDownSelect}
                  styles={styles}
                  label="Select the Organization"
                  options={orgsDropdownOptions}
                  value={currentOrg ? {label: currentOrg.DisplayName, value: currentOrg.ID} : { label: "Select Organization...", value: 0 }}
                  onChange={onSelection}
                />

                <Tooltip title="More">
                  <div>
                    <FontIcon
                      aria-label="More"
                      iconName="MoreVertical"
                      className={classes.moreIconClass}
                      onClick={() => setOrgPanelExpended(!orgPanelExpended)}
                    />
                  </div>
                </Tooltip>
              </div>

              {orgPanelExpended ? _menu : ""}

              {currentOrgLoadingError ? (
                <b style={{ color: "red", padding: "10px" }}>
                  {currentOrgLoadingErrorMsg}
                </b>
              ) : (
                ""
              )}

              <div
                className={
                  currentOrgLoadingError
                    ? classes.noPivotDisplay
                    : classes.pivotDisplay
                }
              >
                <Pivot
                  aria-label="Partner Organizations Listing"
                  onLinkClick={(item?: PivotItem) => {
                    console.log(this);
                  }}
                  styles={pivotStyles}
                >
                  <p>{currentOrgLoadingErrorMsg}</p>
                  <PivotItem
                    headerText="Information"
                    itemIcon="Info"
                    itemKey="0"
                  >
                    <Information
                      isOrgAdmin={isOrgAdmin}
                      isGlobaleAdmin={isGlobalAdmin}
                      currentOrg={currentOrg}
                      token={token}
                      onInfoChange={handleInfoChange}
                    ></Information>
                  </PivotItem>
                  <PivotItem
                    headerText="Student Requests"
                    itemIcon="RecruitmentManagement"
                    itemKey="1"
                  >
                    <StudentsProgramsRequests
                      currentOrg={currentOrg}
                      isOrgAdmin={isOrgAdmin}
                      isGlobaleAdmin={isGlobalAdmin}
                      token={token}
                    ></StudentsProgramsRequests>
                  </PivotItem>

                  <PivotItem headerText="Mentors" itemIcon="People" itemKey="2">
                    <Members
                      currentOrg={currentOrg}
                      isOrgAdmin={isOrgAdmin}
                      isGlobaleAdmin={isGlobalAdmin}
                      token={token}
                    ></Members>
                  </PivotItem>

                  <PivotItem
                    headerText="Administrators"
                    itemIcon="People"
                    itemKey="3"
                  >
                    <Administrators
                      currentOrg={currentOrg}
                      isOrgAdmin={isOrgAdmin}
                      isGlobaleAdmin={isGlobalAdmin}
                      token={token}
                    ></Administrators>
                  </PivotItem>

                  <PivotItem
                    headerText="Students and Associations"
                    itemIcon="Onboarding"
                    itemKey="4"
                  >
                    <StudentsAndAssociations
                      currentOrg={currentOrg}
                      isOrgAdmin={isOrgAdmin}
                      isGlobaleAdmin={isGlobalAdmin}
                      token={token}
                    ></StudentsAndAssociations>
                  </PivotItem>

                  <PivotItem
                    headerText="Affiliated Programs"
                    itemIcon="Questionnaire"
                    itemKey="5"
                  >
                    <AffiliatedPrograms
                      currentOrg={currentOrg}
                      isOrgAdmin={isOrgAdmin}
                      isGlobaleAdmin={isGlobalAdmin}
                      token={token}
                    ></AffiliatedPrograms>
                  </PivotItem>

                  <PivotItem
                    headerText="Documents"
                    itemIcon="Documentation"
                    itemKey="6"
                  >
                    <Documents
                      currentOrg={currentOrg}
                      isGlobaleAdmin={isGlobalAdmin}
                      token={token}
                    ></Documents>
                  </PivotItem>
                </Pivot>
              </div>

              {/* Adding new organization */}

              <Panel
                headerText={"New Organization"}
                isOpen={isAddOrgPanelOpen}
                onDismiss={dismissHandler}
                closeButtonAriaLabel="Close"
                isFooterAtBottom={true}
                onRenderFooterContent={_onRenderFooterContent}
              >
                <Label required={true}>Name</Label>
                <TextField
                  value={newOrgName}
                  onChange={onOrgNameChange}
                  errorMessage={newOrgNameError}
                />{" "}
                <br />
                {orgPanelMessage}
              </Panel>

              {/*Delete org panel */}
              <Dialog
                hidden={hideDeleteDialog}
                onDismiss={() => setHideDeleteDialog(true)}
                dialogContentProps={{
                  type: DialogType.close,
                  title: "Delete Organization",
                  closeButtonAriaLabel: "Close",
                  subText: "Are you sure you want to delete this organization?",
                }}
                modalProps={{
                  titleAriaId: "Delete Org",
                  subtitleAriaId: "Delete Org Sub",
                  isBlocking: false,
                  styles: { main: { maxWidth: 450 } },
                  dragOptions: _dragOptions,
                }}
              >
                <DialogFooter>
                  <PrimaryButton
                    onClick={deleteOrg}
                    text="Delete"
                    style={{ backgroundColor: "#b7472a" }}
                  />
                  <DefaultButton
                    onClick={() => {
                      setHideDeleteDialog(true);
                    }}
                    text="Cancel"
                  />
                </DialogFooter>
              </Dialog>

              {/*New programs panel */}
              <Modal
                titleAriaId={"Manage Programs"}
                subtitleAriaId={"Manage Programs"}
                isOpen={showProgramsModal}
                onDismiss={() => setShowProgramsModal(false)}
                isBlocking={false}
                containerClassName={"ovPOMProgramsContainer"}
                dragOptions={undefined}
              >
                <div className={"ovPOMProgramsHeader"}>
                  <IconButton
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel="Close popup modal"
                    onClick={() => setShowProgramsModal(false)}
                    style={{ float: "right" }}
                  />
                  <div className="sectionHeader">Programs</div>
                </div>
                <div id={"this._subtitleI"} className={"ovPOMProgramsBody"}>
                  {isProgramsLoaded ? (
                    <Programs
                      orgId={currentOrg.ID}
                      modal={handlerClick}
                      isGlobaleAdmin={isGlobalAdmin}
                      currentPrograms={currentPrograms}
                      onProgramChange={handleProgramChange}
                      token={token}
                    ></Programs>
                  ) : (
                    <div
                      style={{
                        height: "200px",
                        alignItems: "center",
                        alignContent: "center",
                        margin: "160px",
                      }}
                    >
                      <Spinner
                        style={{ height: "100%", fontSize: "20px" }}
                        label="Loading programs..."
                        size={SpinnerSize.large}
                      ></Spinner>
                    </div>
                  )}
                </div>
              </Modal>
            </div>
          ) : (
            <Spinner size={SpinnerSize.large} />
          )}
        </div>
      ) : (
        <>
          <div>{accessMessage}</div>
        </>
      )}
      {error && <Error customText="Not able to load, error occured" />}
    </>
  );
};

export default POMAdmin;
