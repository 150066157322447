import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "red",
      textAlign: "center",
      fontWeight: "bold",
    },
  })
);

const Error = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>{props?.code + ": " + props?.text + "- " + props.customText}</p>
    </div>
  );
};
export default Error;
