import { createTheme, ThemeProvider } from "@mui/material/styles";

// This fixes an issue with upgrading MUI 5.0->6.0
import { Theme } from "@mui/material/styles";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles" {
  interface Theme {}
}

let theme = createTheme();
theme = createTheme(theme, {
  palette: {
    background: {
      default: "#fafafa" //off-white
    },
    primary: {
      main: "#006cb9", //blue
      light: "#d7efff", //light blue
      dark: "#004e83", //dark blue
    },
    secondary: {
      main: "#3d3c41", //gray
      light: "#dfdbf0", //light gray
    },
  },
});

const DCPSThemeProvider = (props: any) => {
  return <ThemeProvider theme={theme} {...props} />;
};

export default DCPSThemeProvider;
