import { useEffect, useState } from "react";
import classes from "./PartnerManager.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  DefaultButton,
  PrimaryButton,
  IButtonProps,
} from "@fluentui/react/lib/Button";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import { Spinner, SpinnerSize, TextField } from "@fluentui/react";

import {
  getStudentAndAssociation,
  updateMentorMap,
  addMentorMap,
} from "../../services/POMAdmin";

import Select from "react-select";

import { Checkbox } from "@mui/material";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { Label } from "@fluentui/react/lib/Label";

import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import Error from "../Exceptions/TokenError";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

export interface IDetailsListStudentsAndAssociationsProps {
  currentOrg: any;
  isOrgAdmin: boolean;
  isGlobaleAdmin: boolean;
  token: string;
}

export interface IMentorMap {
  key: string;
  requestId: string;
  studentId: string;
  isExpired: string;
  studentDisplayName: string;
  studentEmail: string;
  studentSchool: string;
  studentGrade: string;
  hasMentor: boolean;
  mentorDisplayName: string;
  mentorEmail: string;
  mentorId: string;
  programId: string;
  programDisplayName: string;
  orgId: string;
}

const StudentsAndAssociations = (
  props: IDetailsListStudentsAndAssociationsProps
) => {
  // let _allStudentItems: any = [];
  //let studentsOptions: any[] = [];
  //let mentorOptions: any[] = [];

  let errText: string = "";

  const [studentItems, setStudentItems] = useState<any>([]);
  const [studentsOptions, setStudentsOptions] = useState<any>([]);
  const [mentorOptions, setMentorOptions] = useState<any>([]);
  const [showLoading, setShowLoading] = useState(true);
  const [checkboxValue, setCheckboxValue] = useState("");
  const [selectedKey, setSelectedKey] = useState(false);
  const [isAddMentorMapDisabled, setIsAddMentorMapDisabled] = useState(false);
  const [isEditMentorMapDisabled, setIsEditMentorMapDisabled] = useState(true);
  const [isNewMentorMapPanelOpen, setIsNewMentorMapPanelOpen] = useState(false);
  const [isEditMappingPanelOpen, setIsEditMappingPanelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [selectedMentorID, setSelectedMentorID] = useState("");
  const [selectedStudentRequestID, setSelectedStudentRequestID] = useState("");
  const [error, setError] = useState(false);
  const [allStudentItems, setAllStudentItems] = useState<any>([]);
  const [sortAsc, setSortAsc] = useState<boolean>(true);

  const token = useSelector((state: RootState) => state.token.token);

  const __generateStudentsAndAssociation = (response: any) => {
    console.log(response);
    let items: IMentorMap[] = [];
    response.map((student: any, i: any) => {
      return items.push({
        key: i.toString(),
        requestId: student.RequestID,
        studentId: student.StudentID,
        isExpired: student.IsExpired ? "Yes" : "No",
        studentDisplayName: student.Student.DisplayName,
        studentEmail: "s" + student.StudentID + "@students.duvalschools.org",
        studentSchool: student.Student.School,
        studentGrade: student.Student.GradeLevel,
        hasMentor: student.HasMentor,
        mentorDisplayName: student.HasMentor ? student.Mentor.DisplayName : "",
        mentorEmail: student.HasMentor ? student.Mentor.Email : "",
        mentorId: student.HasMentor ? student.Mentor.ID : "",
        programId: student.ProgramID,
        programDisplayName: student.ProgramDisplayName,
        orgId: student.OrganizationID,
      });
    });
    //console.log("organizeditems", items);
    return items;
  };

  useEffect(() => {
    if (props.currentOrg) {
      getStudentAndAssociation(props.currentOrg.ID, props.token)
        .then((response) => {
          setShowLoading(false);
          setSelectedKey(false);
          setIsEditMentorMapDisabled(true);
          setStudentItems(__generateStudentsAndAssociation(response.Students));
          setAllStudentItems(
            __generateStudentsAndAssociation(response.Students)
          );
          getStudentMentorOptions(
            __generateStudentsAndAssociation(response.Students),
            response.Members
          );
        })
        .catch((err: any) => {
          errText = err;

          setError(true);
        });
    }
  }, [props.currentOrg]);

  const onclickHandlerNew = () => {
    setIsNewMentorMapPanelOpen(true);
    setSelectedStudentRequestID("");
    setSelectedMentorID("");
  };

  const onclickHandlerEdit = () => {
    setIsEditMappingPanelOpen(true);
    setSelectedStudentRequestID("");
    setSelectedMentorID("");
  };

  const _mentorMapItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: onclickHandlerNew,

      disabled: isAddMentorMapDisabled || !props.isOrgAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
    {
      key: "edit",
      text: "Edit",
      onClick: onclickHandlerEdit,

      iconProps: { iconName: "Edit" },
      disabled: isEditMentorMapDisabled || !props.isOrgAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
  ];
  const _farItems: ICommandBarItemProps[] = [
    {
      key: "tile",
      onRender: () => (
        <SearchBox
          placeholder="Filter by student name"
          className="searchBox"
          onSearch={(newValue) => _onSearchChange(newValue)}
          onChange={(ev) => _onSearchChange(ev?.target?.value)}
        />
      ),
    },
  ];

  const overflowProps: IButtonProps = {
    ariaLabel: "More commands",
    menuIconProps: {
      iconName: "ChevronDown",
    },
    styles: {
      menuIcon: {
        fontSize: "20px",
        color: "#0078d4",
      },
    },
  };

  const columnStyling = (decision: string) => {
    // console.log(decision);

    switch (decision) {
      case "No":
        return classes.approved;

      default:
        return classes.denied;
    }
  };

  const columnSort = (column: any) => {
    //alert("clicked");
    let obj = [...studentItems];
    //console.log(this.state.sortAsc);
    //console.log(column);

    if (sortAsc) {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? 1 : -1;
      });
    } else {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? -1 : 1;
      });
    }

    // console.log(obj);

    setStudentItems([...obj]);

    setSortAsc(!sortAsc);
  };

  const submitNewStudent = () => {
    setShowLoading(true);
    setIsNewMentorMapPanelOpen(false);

    addMentorMap(
      selectedStudentRequestID,
      props.currentOrg.ID,
      selectedMentorID,
      token
    ).then((mapInfo) => {
      // console.log(">>>>> mapInfo >>>>>");
      // console.log(mapInfo);

      if (mapInfo) {
        if (mapInfo.ExceptionMessage) {
          setShowLoading(false);
          alert(mapInfo.ExceptionMessage);
        } else {
          getStudentAndAssociation(props.currentOrg.ID, props.token).then(
            (_r) => {
              let _x = __generateStudentsAndAssociation(_r.Students);

              setShowLoading(false);

              setStudentItems(_x);
              setSelectedKey(false);

              getStudentMentorOptions(studentItems, _r.Members);
            }
          );
        }
      } else {
        getStudentAndAssociation(props.currentOrg.ID, props.token).then(
          (_r) => {
            let _x = __generateStudentsAndAssociation(_r.Students);

            setShowLoading(false);

            setStudentItems(_x);
            setSelectedKey(false);

            getStudentMentorOptions(studentItems, _r.Members);
          }
        );
      }
    });
  };

  const OnChangeNewStudent = (item: any) => {
    // console.log(item);
    setSelectedStudentRequestID(item.value);
  };

  const OnChangeMentorsOption = (item: any): void => {
    // console.log(" mentor item pp +++++++++++++++++++");
    //console.log(item);
    setSelectedMentorID(item.value);
  };
  const getStudentMentorOptions = (list: any, mentorsList: any) => {
    //console.log(list);
    let studentsOptionsTemp: any = [];
    let mentorOptionsTemp: any = [];
    // console.log(mentorsList);

    let stu_obj = list;
    let mentor_obj = mentorsList;

    stu_obj.map((i: any) => {
      let item = {
        value: i.requestId,
        label: i.studentDisplayName + "(" + i.programDisplayName + ")",
      };

      return studentsOptionsTemp.push(item);
    });

    // console.log(studentsOptionsTemp);
    setStudentsOptions([...studentsOptionsTemp]);
    mentor_obj.map((i: any) => {
      let item = {
        value: i.ID,
        label: i.DisplayName,
      };

      // if (this.mentorOptions.map((x) => x.value).indexOf(item.value) < 0) {
      return mentorOptionsTemp.push(item);
      // }
    });

    setMentorOptions([...mentorOptionsTemp]);

    //console.log(this.studentsOptions);
    //console.log(this.mentorOptions);
  };
  const _onRenderFooterContent = (): JSX.Element => {
    var _r = <div>Footer</div>;

    _r = (
      <div>
        <PrimaryButton
          onClick={submitNewStudent}
          style={{ marginRight: "8px" }}
          disabled={
            !(
              selectedMentorID.length > 0 && selectedStudentRequestID.length > 0
            )
          }
        >
          Save
        </PrimaryButton>
        <DefaultButton
          onClick={() => setIsNewMentorMapPanelOpen(false)}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const _onRenderEditFooterContent = (): JSX.Element => {
    var _r = <div>Footer</div>;

    _r = (
      <div>
        <PrimaryButton
          onClick={submitEditStudent}
          style={{ marginRight: "8px" }}
          disabled={selectedMentorID.length === 0}
        >
          Save
        </PrimaryButton>
        <DefaultButton
          onClick={() => {
            setIsEditMappingPanelOpen(false);
          }}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const submitEditStudent = () => {
    setShowLoading(true);
    setIsEditMappingPanelOpen(false);

    updateMentorMap(
      selectedItem.requestId,
      props.currentOrg.ID,
      selectedMentorID,
      token
    ).then((mapInfo) => {
      // console.log("mapInfo bacl...");
      // console.log(mapInfo);

      if (mapInfo) {
        if (mapInfo.ExceptionMessage) {
          setShowLoading(false);
          alert(mapInfo.ExceptionMessage);
        } else {
          getStudentAndAssociation(props.currentOrg.ID, token).then((_r) => {
            setShowLoading(false);

            setStudentItems(__generateStudentsAndAssociation(_r.Students));
            setSelectedKey(false);
            setIsEditMentorMapDisabled(true);

            getStudentMentorOptions(
              __generateStudentsAndAssociation(_r.Students),
              _r.Members
            );
          });
        }
      } else {
        getStudentAndAssociation(props.currentOrg.ID, token).then((_r) => {
          // console.log("_r");
          // console.log(_r);

          let _x = __generateStudentsAndAssociation(_r.Students);

          // console.log("_x");
          // console.log(_x);
          setShowLoading(false);

          setStudentItems(_x);
          setSelectedKey(false);
          setIsEditMentorMapDisabled(true);

          getStudentMentorOptions(studentItems, _r.Members);
        });
      }
    });
  };

  const onChangeCheckbox = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,

    request: any
  ) => {
    //console.log("selecte key", this.state.selectedKey);
    console.log(request);

    setSelectedKey(checked);
    setCheckboxValue(ev.target.value);

    if (checked) {
      setIsEditMentorMapDisabled(false);
      setSelectedItem(request);
    } else {
      setIsEditMentorMapDisabled(true);
    }
  };

  const _onSearchChange = (text: any): void => {
    setStudentItems(
      !!text
        ? allStudentItems.filter(
            (i: any) =>
              i.studentDisplayName
                .toLowerCase()
                .indexOf(text.toLocaleLowerCase()) > -1
          )
        : allStudentItems
    );
  };

  return (
    <div style={{ margin: "1%" }}>
      <p style={{ fontSize: "20px", color: "#006cb9" }}>
        Students and Associations: {props.currentOrg.DisplayName}
      </p>
      <CommandBar
        items={_mentorMapItems}
        farItems={_farItems}
        overflowButtonProps={overflowProps}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      <Table className={classes.tableList}>
        <Thead>
          <Tr>
            <Th>Select</Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("isExpired")}
            >
              Expired{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentId")}
            >
              Student Number{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentDisplayName")}
            >
              Student Name{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentEmail")}
            >
              Student Email{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentSchool")}
            >
              Student School{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentGrade")}
            >
              Student Grade{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("mentorDisplayName")}
            >
              Mentor{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("mentorEmail")}
            >
              Mentor Email{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
          </Tr>
        </Thead>
        {!showLoading && (
          studentItems.length > 0 && (
            studentItems.map((request: any, i: any) => {
              return (
                <Tbody key={request.key}>
                  <Tr key={request.key}>
                    <Td>
                      <Checkbox
                        value={i}
                        className={
                          request.isExpired === "No"
                            ? classes.checkBox
                            : classes.checkBoxDisabled
                        }
                        checked={
                          i.toString() === checkboxValue ? selectedKey : false
                        }
                        disabled={request.isExpired === "Yes" ? true : false}
                        onChange={(e, checked) => {
                          onChangeCheckbox(e, checked, request);
                        }}
                      />
                    </Td>
                    <Td className={columnStyling(request.isExpired)}>
                      <b>{request.isExpired}</b>
                    </Td>
                    <Td>{request.studentId}</Td>
                    <Td>{request.studentDisplayName}</Td>
                    <Td>{request.studentEmail}</Td>
                    <Td>{request.studentSchool}</Td>
                    <Td>{request.studentGrade}</Td>
                    <Td>{request.mentorDisplayName}</Td>
                    <Td>{request.mentorEmail}</Td>
                  </Tr>
                </Tbody>
              );
            })
          )
        )}
      </Table>
      {showLoading && (
        <div className={classes.spinnerPos}>
            <Spinner size={SpinnerSize.large}></Spinner>
        </div>)
      }
      {!showLoading && studentItems.length == 0 && (
          <div className={classes.spinnerPos}>
            <b>List Empty</b>
          </div>)
      }
      {error && <Error text={errText} customText="Not able to load" />}

      <Panel
        headerText={"New Student/Mentor Mapping"}
        isOpen={isNewMentorMapPanelOpen}
        onDismiss={() => setIsNewMentorMapPanelOpen(false)}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        isFooterAtBottom={true}
        onRenderFooterContent={_onRenderFooterContent}
      >
        <br />
        <Label>Student/Request</Label>

        <Select
          options={studentsOptions}
          onChange={(opt) => OnChangeNewStudent(opt)}
          className={classes.dropdown}
          placeholder={"Select a student request"}
          required
        />

        <br />
        <Label>Mentor</Label>
        <Select
          options={mentorOptions}
          onChange={(opt) => OnChangeMentorsOption(opt)}
          className={classes.dropdown}
          placeholder={"Select a mentor"}
          required
        />
        <div style={{ color: "red" }}>
          {/* {this.state.mentorsOptionValueError} */}
        </div>
      </Panel>

      <Panel
        headerText="Edit Student/Mentor Mapping"
        isOpen={isEditMappingPanelOpen}
        onDismiss={() => {
          setIsEditMappingPanelOpen(false);
        }}
        closeButtonAriaLabel="Close"
        onRenderFooterContent={_onRenderEditFooterContent}
        type={PanelType.medium}
        isFooterAtBottom={true}
      >
        <Label>Student</Label>
        <TextField
          title={"Student"}
          defaultValue={selectedItem.studentDisplayName}
          disabled={true}
        ></TextField>

        <br />
        <Label>Mentor</Label>
        <Select
          options={mentorOptions}
          onChange={(opt) => OnChangeMentorsOption(opt)}
          // TODO styles={{ dropdown: { width: 400 } }}
          className={classes.dropdown}
          placeholder={"Select a mentor"}
          required
        />
        <div style={{ color: "red" }}>
          {/* {this.state.mentorsOptionValueError} */}
        </div>
      </Panel>
    </div>
  );
};

export default StudentsAndAssociations;
