import { useEffect, useState } from "react";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Icon } from "@fluentui/react/lib/Icon";
import { getDocuments } from "../../services/POMAdmin";
import classes from "./PartnerManager.module.css";

import { Spinner, SpinnerSize } from "@fluentui/react";
import { Checkbox } from "@mui/material";
import { Modal } from "@fluentui/react";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { IconButton, IButtonProps } from "@fluentui/react/lib/Button";

import { Favicon } from "../../img/Icons";
import Error from "../Exceptions/TokenError";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";

export interface IDocument {
  key: string;
  fileType: string;
  schoolYear: string;
  studentNumber: string;
  student: any;
  studentSchool: string;
  studentGrade: string;
  organizationDisplayName: string;
  programDisplayName: string;
  formExpiration: any;
  studentDOB: any;
  requestor: string;
}

export interface IDetailsListDocumentsProps {
  currentOrg: any;
  isGlobaleAdmin: boolean;
  token: string;
}

const Documents = (props: IDetailsListDocumentsProps) => {
  
  
  let errText: string = "";

  const token = useSelector((state: RootState) => state.token.token);

  const [foundDocuments, setFoundDocuments] = useState<any>({});
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [checkboxValue, setCheckboxValue] = useState<string>("");
  const [selectedKey, setSelectedKey] = useState<boolean>(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState<boolean>(true);
  const [loadDocument, setLoadDocument] = useState<boolean>(false);
  const [selectedDoc, setSelectedDoc] = useState<any>([]);
  const [error, setError] = useState<boolean>(false);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [itemsTemp, setItemsTemp] = useState<any>([]);

  useEffect(() => {
    if (props.currentOrg) {
      getDocuments(props.currentOrg.ID, token)
        .then((response) => {
          //console.log("response members CDM", response);
          _generateDocuments(response);

          setFoundDocuments(_generateDocuments(response));
          setItemsTemp(_generateDocuments(response));
          setShowLoading(false);
          setSelectedKey(false);
          setIsDownloadDisabled(true);

          
        })
        .catch((err: any) => {
          //console.log(err);
          errText = err;
          setError(true);
        });
    }
  }, [props.currentOrg]);

  const columnSort = (column: any) => {
    //alert("clicked");
   // console.log("before sort", foundDocuments);
    let obj = [...foundDocuments];
    //console.log(sortAsc);
    //console.log(column);

    if (sortAsc) {
      console.log("asc");
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? 1 : -1;
      });
    } else {
      console.log("des");
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? -1 : 1;
      });
    }

    //console.log("after sort Obj", obj);
    setSortAsc(!sortAsc);
    //console.log("toggle",sortAsc);
    setFoundDocuments([...obj]);

     //console.log("after sort state", foundDocuments);

    
  };

  const onChangeCheckbox = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    index: any,
    request: any
  ) => {
    //console.log("selecte key", this.state.selectedKey);
    console.log(ev.target.value, checked, index, checkboxValue);

    setSelectedKey(checked);
    setCheckboxValue(ev.target.value);

    if (checked) {
      setIsDownloadDisabled(false);
      setSelectedDoc(request);
    } else {
      setIsDownloadDisabled(true);
    }
  };

  const _onSearchChange = (text: any): void => {
    setFoundDocuments(
      !!text
        ? itemsTemp.filter(
            (i: any) =>
              i.student.toLowerCase().indexOf(text.toLocaleLowerCase()) > -1
          )
        : itemsTemp
    );
  };

  const _generateDocuments = (response: any) => {
    //console.log(response);
    let items: IDocument[] = [];

    response.map((doc: any, i: any) => {
      return items.push({
        key: i.toString(),
        fileType: "file.pdf",
        schoolYear: new Date(doc.FormExpiration).getFullYear().toString(),
        studentNumber: doc.Student.ID,
        student: doc.Student.DisplayName,
        studentSchool: doc.Student.School,
        studentGrade: doc.Student.GradeLevel,
        organizationDisplayName: doc.OrganizationDisplayName,
        programDisplayName: doc.ProgramDisplayName,
        formExpiration: formatted_date(doc.FormExpiration),
        studentDOB: formatted_date(doc.Student.DateOfBirth),
        requestor: doc.Requestor.DisplayName,
      });
    });
    //console.log(items);
    return items;
  };

  const formatted_date = (date: any) => {
    let _d = new Date(date);
    let formatted_date =
      _d.getMonth() + 1 + "/" + _d.getDate() + "/" + _d.getFullYear();

    return formatted_date;
  };

  const _items: ICommandBarItemProps[] = [
    {
      key: "view",
      text: "View",
      iconProps: { iconName: "View" },
      onClick: () => setLoadDocument(true),
      disabled: isDownloadDisabled,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
  ];
  const _farItems: ICommandBarItemProps[] = [
    {
      key: "tile",
      onRender: () => (
        <SearchBox
          placeholder="Filter by student name"
          className="searchBox"
          onSearch={(newValue) => _onSearchChange(newValue)}
          onChange={(ev) => _onSearchChange(ev?.target?.value)}
        />
      ),
    },
  ];

  const overflowProps: IButtonProps = {
    ariaLabel: "More commands",
    menuIconProps: {
      iconName: "ChevronDown",
    },
    styles: {
      menuIcon: {
        fontSize: "20px",
        color: "#0078d4",
      },
    },
  };

  return (
    <div style={{ margin: "1%" }}>
      <p style={{ fontSize: "20px", color: "#006cb9" }}>
        Documents : {props.currentOrg.DisplayName}
      </p>
      <CommandBar
        items={_items}
        farItems={_farItems}
        overflowButtonProps={overflowProps}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      {loadDocument && (
        <Modal
          isOpen={loadDocument}
          onDismiss={() => setLoadDocument(false)}
          containerClassName={"SubmitRequestContainer"}
          scrollableContentClassName={"SubmitRequestScroll"}
        >
          <div className={"SubmitRequestContainerHeader"}>
            <IconButton
              styles={{
                root: {
                  marginLeft: "4px",
                  marginTop: "4px",
                  marginRight: "2px",
                  float: "right",
                },
                rootHovered: {},
              }}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close request form"
              onClick={() => setLoadDocument(false)}
            />
          </div>

          <div className={"SubmitRequestContainerBody"}>
            <IconButton
              styles={{
                root: {
                  marginLeft: "4px",
                  marginTop: "4px",
                  marginRight: "2px",
                  float: "right",
                  fontSize: 18,
                },
                rootHovered: {},
              }}
              iconProps={{ iconName: "Print" }}
              ariaLabel="Print Fprm"
              onClick={() => window.print()}
            />

            <div className={"DocumentViewContainer"}>
              <div className={"DocumentViewRow"}>
                <div className={"SubmitRequestContainerBodyHeader"}>
                  <Favicon alt="Form logo" width="100px" height="50px" />
                </div>
                <div className={"SubmitRequestContainerBodyHeader"}>
                  <h3>HomeRoom PORTAL PARENT FERPA CONSENT FORM</h3>
                </div>

                <div className={"SubmitRequestContainerBodyText"}>
                  <p>
                    {" "}
                    I consent to the disclosure of the following educational
                    records of{" "}
                    <span className={"cursiveFont"}>
                      <b> {selectedDoc.student}</b>
                    </span>{" "}
                    to{" "}
                    <span className={"cursiveFont"}>
                      <b> {selectedDoc.programDisplayName}</b>
                    </span>{" "}
                    and the Jacksonville Public Education Fund ("Data
                    Recipients") and I understand that the following educational
                    records will be available to facilitate research to improve
                    instruction and student supports throughout Duval County.
                    <br />
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textJustify: "auto",
                      justifySelf: "center",
                    }}
                  >
                    <span
                      className={"cursiveFont"}
                      style={{ padding: 20, textAlign: "center" }}
                    >
                      <b> {selectedDoc.student}</b> <br />
                      <b> {selectedDoc.studentNumber} </b>
                      <br />
                      <b> {selectedDoc.studentDOB} </b>
                      <br />
                    </span>
                  </div>
                  All of the following educational records are approved for
                  release:
                  <ul>
                    <li>All student and family demographics</li>
                    <li>All academic data</li>
                    <li>All district/state/natlonat student assessments</li>
                    <li>All attendance data</li>
                    <li>All student services data including discipline</li>
                    <li>
                      All data contained on the HomeRoom parent and health
                      portal
                    </li>
                  </ul>
                  <br />
                  <p>
                    Written consent of parents is usually required for the
                    release of personally identifiable records or other
                    information protected by the Family Education and Privacy
                    Act to agencies or individuals. Agencies or individuas may
                    not share information with any other party without the
                    wrltten consent of the parents unless entitled to do so
                    under FERPA 99.33. You may révoke this authorization at any
                    time. Unless revoked earlier, this consent will remain in
                    effect until one school year (August 1 st to July 31 st of
                    the following year) after program enrollment.
                  </p>
                  <span
                    className={"cursiveFont"}
                    style={{ padding: 20, textAlign: "center" }}
                  >
                    <b>{selectedDoc.requestor}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Table className={classes.tableList}>
        <Thead>
          <Tr>
            <Th>Select</Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("schoolYear")}
            >
              School Year{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>{" "}
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentNumber")}
            >
              Student Number{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("student")}
            >
              Student Name{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentSchool")}
            >
              Student School{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentGrade")}
            >
              Student Grade{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>

            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("formExpiration")}
            >
              Expire{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
          </Tr>
        </Thead>
        {!showLoading && (
          foundDocuments.length > 0 && (
            foundDocuments.map((request: any, index: any) => {
              //console.log("table", foundDocuments);
              return (
                <Tbody key={request.key}>
                  <Tr key={request.StudentNumber}>
                    <Td>
                      <Checkbox
                        value={index}
                        className={classes.checkBox}
                        checked={
                          index.toString() === checkboxValue
                            ? selectedKey
                            : false
                        }
                        onChange={(e, checked) => {
                          onChangeCheckbox(e, checked, index, request);
                        }}
                      />
                    </Td>
                    <Td>{request.schoolYear}</Td>
                    <Td>{request.studentNumber}</Td>
                    <Td>{request.student}</Td>
                    <Td>{request.studentSchool}</Td>
                    <Td>{request.studentGrade}</Td>

                    <Td>{request.formExpiration}</Td>
                  </Tr>
                </Tbody>
              );
            })
          )
        )}
      </Table>
        {showLoading && (
          <div className={classes.spinnerPos}>
            <Spinner size={SpinnerSize.large}></Spinner>
        </div>)
        }
        {foundDocuments.length == 0 && (
            <div className={classes.spinnerPos}>
              <b>List Empty</b>
            </div>)
        }
        {error && <Error text={errText} customText="Not able to load" />}
    </div>
  );
};

export default Documents;
