import { useEffect, useState } from "react";

import classes from "./FERPA.module.css";

import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Dialog, DialogFooter } from "@fluentui/react/lib/Dialog";

import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";

import { formatDate } from "../../services/Common";

import { RootState } from "../../store";
import { useSelector } from "react-redux";

import defaultStore from '../../util/Globale'

export interface IMyRequest {
  key: string;
  ID: string;
  DisplayName: string;
  Program: string;
  Requestor: string;
  Student: string;
  Created: string;
  Decision: string;

  RequestType: string;
  IsDecided: boolean;
  IsExpired: boolean;
}

const MyRequests = (props: any) => {
  const [items, setItems] = useState<IMyRequest[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [currentRequestId, setCurrentRequestId] = useState<string>("");

  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);

  const token = useSelector((state: RootState) => state.token.token);

  useEffect(() => {
    let _allItems: IMyRequest[];
    _allItems = _generateMyRequests(props.children);
    setItems(_allItems);
  }, []);

  let Students = items;

  let noRequestsMessage = "";

  if (Students.length === 0) {
    noRequestsMessage = "There are no requests.";
  }

  // to send retract request when user clciks on
  const retractRequest = async () => {
    setShowDeleteModal(false);
    setShowLoadingMessage(true);

    let _r = {
      ID: currentRequestId,
    };

    const response = await fetch(
      defaultStore.webAPIMainEndPoint + "/api/outreach/retractprogramrequest",
      {
        method: "POST",
        body: JSON.stringify(_r),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json;odata=verbose",
          Authorization: "Bearer " + token,
          "X-app-location": window.location.origin + window.location.pathname,        
        },
      }
    );

    if (response.ok) {
      setShowLoadingMessage(false);
      window.location.reload();
    }
  };

  // for organizing the data coming from props
  const _generateMyRequests = (seedItems: any) => {
    const items: IMyRequest[] = [];

    var Requests = seedItems;

    if (Requests) {
      const newItems: any[] = _copyAndSort(Requests, "Created", true);

      for (let i = 0; i < newItems.length; i++) {
        items.push({
          key: i.toString(),
          ID: newItems[i]["ID"],
          Requestor: newItems[i]["RequestorInfo"]["DisplayName"],
          RequestType: newItems[i]["RequestType"],
          DisplayName: newItems[i]["DisplayName"],
          Program: newItems[i]["ProgramDisplayName"],
          Student: newItems[i]["StudentInfo"]["DisplayName"],
          Created: newItems[i]["Created"],
          Decision: newItems[i]["Decision"],
          IsDecided: newItems[i]["IsDecided"],
          IsExpired: newItems[i]["IsExpired"],
        });
      }
    }

    return items;
  };

  function _copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }

  const onClickHandler = (item: any) => {
    console.log("retract", item);
    if (item.Decision === "Pending") {
      setShowDeleteModal(true);
      setCurrentRequestId(item.ID);
    }
  };
  return (
    <>
      <div style={{ margin: "20px 0" }}>
        <br />
        <h2>Requests</h2>
        <Table className={classes.requestStudentList}>
          <Thead>
            <Tr>
              <Th>Student</Th>
              <Th>Requestor</Th>
              <Th>Program</Th>
              <Th>Status</Th>
              <Th>Created Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item: any) => {
              //console.log(item);
              return (
                <Tr key={item.key}>
                  <Td className={classes.miscHeader}>{item.Student}</Td>
                  <Td className={classes.miscHeader}>{item.Requestor}</Td>
                  <Td className={classes.miscHeader}>{item.Program}</Td>
                  <Td
                    className={
                      item.Decision === "Pending"
                        ? classes.pending
                        : item.Decision === "Approved"
                        ? classes.approved
                        : classes.status
                    }
                  >
                    {item.Decision}
                  </Td>
                  <Td className={classes.miscHeader}>{formatDate(item.Created)}</Td>
                  <Td
                    className={
                      item.Decision === "Pending"
                        ? classes.retract
                        : classes.noretract
                    }
                    onClick={() => onClickHandler(item)}
                  >
                    {item.Decision === "Pending"
                      ? "Retract"
                      : "No Action Required"}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <div>
          {showLoadingMessage && (
            <Spinner
              style={{ height: "100%", fontSize: "20px" }}
              label="updating..."
              size={SpinnerSize.large}
            ></Spinner>
          )}
        </div>

        <Dialog
          hidden={!showDeleteModal}
          onDismiss={() => setShowDeleteModal(false)}
        >
          <div style={{ padding: 10 }}>
            Are you sure you want to retract this request?
          </div>
          <DialogFooter>
            <PrimaryButton
              onClick={retractRequest}
              text="Retract"
              style={{ backgroundColor: "#d13438", color: "#fff" }}
            />
            <DefaultButton
              onClick={() => setShowDeleteModal(false)}
              text="Cancel"
            />
          </DialogFooter>
        </Dialog>
      </div>
      <div className={classes.noStudents}>
        <div>{noRequestsMessage}</div>
      </div>
    </>
  );
};

export default MyRequests;
