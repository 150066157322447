import { useEffect, useState } from "react";

import { Drawer } from "@mui/material";
import { PrimaryButton } from "@fluentui/react/lib/Button";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import classes from "./FERPA.module.css";

require("../../style/PomParents.scss");

export interface IMyStudent {
  key: string;

  FirstName: string;
  LastName: string;
  DateOfBirth: string;
  School: string;
  ResponsibilityCenter: string;
  GradeLevel: string;
  Guardians: string[];
  GuardiansLookUp: any[];
  GuardianHtml: any;
  GuardiansCount: number;
  GuardianName: string;
  GuardianEmail: string;
  ID: string;
  DisplayName: string;
  ObjectType: string;
  FormattedDisplayName: string;
}

const MyStudents = (props: any) => {
  const [items, setItems] = useState<IMyStudent[]>([]);

  const [isStudentInfoPanelOpen, setIsStudentInfoPanelOpen] =
    useState<boolean>(false);

  const [currentStudent, setCurrentStudent] = useState<any>();

  const [FirstName, setFirstName] = useState<string>("");
  const [LastName, setLastName] = useState<string>("");
  const [DateOfBirth, setDateOfBirth] = useState<string>("");

  const [GuardiansHtml, setGuardiansHtml] = useState<any>(null);

  useEffect(() => {
    let _allItems: IMyStudent[];
    _allItems = _generateMyStudents(props.children);
    setItems(_allItems);
  }, []);

  function _copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }

  const closeStudentInfoPanel = () => {
    setIsStudentInfoPanelOpen(false);
  };

  // student info panel update
  const openStudentInfoPanel = (item: any) => {
    let dob = new Date(item["DateOfBirth"]);

    let _gsHtml: JSX.Element[] = [];

    if (item["GuardiansLookUp"]) {
      for (var _g in item["GuardiansLookUp"]) {
        let _tmp = (
          <>
            <span>
              {item["GuardiansLookUp"][_g]["DisplayName"]}
              {" - "}
              {item["GuardiansLookUp"][_g]["Email"]}
            </span>
            <br />
          </>
        );

        _gsHtml.push(_tmp);
      }
    }

    setCurrentStudent(item);

    setFirstName(item["FirstName"]);
    setLastName(item["LastName"]);

    setGuardiansHtml(_gsHtml);
    setDateOfBirth(
      dob.getMonth() + "/" + dob.getDay() + "/" + dob.getFullYear()
    );

    setIsStudentInfoPanelOpen(true);
  };

  // Organizing students panel and the students list arrangement
  const _generateMyStudents = (seedItems: any) => {
    const items_temp: IMyStudent[] = [];

    var StudentsTMP = seedItems;

    if (StudentsTMP) {
      let Students: any[] = _copyAndSort(StudentsTMP, "DisplayName", false);

      for (let i = 0; i < Students.length; i++) {
        let _legalGuardianName = "";
        let _legalGuardianEmail = "";
        let _guardiansLookup = null;
        let _gsHtml: any = null;

        try {
          if (Students[i]["Properties"]["GuardianLookup"]) {
            _guardiansLookup = Students[i]["Properties"]["GuardianLookup"];

            let firstKey = Object.keys(
              Students[i]["Properties"]["GuardianLookup"]
            )[0];
            _legalGuardianName =
              Students[i]["Properties"]["GuardianLookup"][firstKey][
                "DisplayName"
              ];
            _legalGuardianEmail =
              Students[i]["Properties"]["GuardianLookup"][firstKey]["Email"];
          }
        } catch (e) {
          console.log(e);
        }

        let _guardianCount = 0;
        _guardianCount = Students[i]["Guardians"].length;

        items_temp.push({
          key: i.toString(),
          FirstName: Students[i]["FirstName"],
          LastName: Students[i]["LastName"],
          DateOfBirth: Students[i]["DateOfBirth"],
          School: Students[i]["School"],
          ResponsibilityCenter: Students[i]["ResponsibilityCenter"],
          GradeLevel: Students[i]["GradeLevel"],
          Guardians: Students[i]["Guardians"],
          GuardiansLookUp: _guardiansLookup,
          GuardianHtml: _gsHtml,
          GuardiansCount: _guardianCount,
          GuardianName: _legalGuardianName,
          GuardianEmail: _legalGuardianEmail,
          ID: Students[i]["ID"],
          DisplayName: Students[i]["DisplayName"],
          ObjectType: Students[i]["ObjectType"],
          FormattedDisplayName:
            Students[i]["FirstName"] + " " + Students[i]["LastName"],
        });
      }
    }

    return items_temp;
  };

  let noStudentsMessage = null;

  if (items.length === 0) {
    noStudentsMessage = (
      <>
        {" "}
        There are no registered students. Please{" "}
        <a href="https://duvalschoolsorg.sharepoint.com/sites/DHR/SitePages/Legacy.aspx?ovtarget=https://duvalschoolsorg.sharepoint.com/sites/OneView/Pages/AddStudent.aspx">
          add a student to your account
        </a>
      </>
    );
  }

  var _content = (
    <>
      <div style={{ margin: "20px 0" }} key="studentTable">
        <h2>Students</h2>
        <Table className={classes.studentList}>
          <Thead>
            <Tr>
              <Th>Student Name</Th>
              <Th>Student Id</Th>
              <Th>School</Th>
              <Th>Responsible Center</Th>
              <Th>Guardians</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item: any) => {
              return (
                <Tr key={item.ID} onClick={() => openStudentInfoPanel(item)}>
                  <Td>{item.FormattedDisplayName}</Td>
                  <Td>{item.ID}</Td>
                  <Td>{item.School}</Td>
                  <Td>{item.ResponsibilityCenter}</Td>
                  <Td>{item.GuardiansCount}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Drawer
          anchor="right"
          open={isStudentInfoPanelOpen}
          onClose={closeStudentInfoPanel}
        >
          <div className={classes.studentListPanel}>
            <h3 style={{ textAlign: "left" }}>Student Info</h3>
            <b>First Name:</b> {FirstName}
            <br />
            <b> Last Name:</b> {LastName} <br />
            <b>Student Number: </b> {currentStudent ? currentStudent["ID"] : ""}
            <br />
            <b> DOB: </b> {currentStudent ? DateOfBirth : ""}
            <br />
            <b>Grade: </b> {currentStudent ? currentStudent["GradeLevel"] : ""}
            <br />
            <b>Responsibility Center:</b>{" "}
            {currentStudent ? currentStudent["ResponsibilityCenter"] : ""}
            <br />
            <b>School:</b> {currentStudent ? currentStudent["School"] : ""}
            <br />
            <b>Guardians:</b> <br />
            {GuardiansHtml ? GuardiansHtml : null}
            <PrimaryButton
              onClick={closeStudentInfoPanel}
              style={{ marginRight: "20px", marginTop: "40px" }}
            >
              Close
            </PrimaryButton>
          </div>
        </Drawer>
      </div>

      <div className={classes.noStudents} key="noStudents">
        {noStudentsMessage}
      </div>
    </>
  );
  return _content;
};

export default MyStudents;
