import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from '@mui/material/styles';
import defaultStore from "../../util/Globale";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            position: 'fixed',
            left: 0,
            bottom: 0,
            width: '100%',
            backgroundColor: '#EAEAEA',
            color: '#949494',
            textAlign: 'right',
            fontSize: 10,
            padding: '5px 20px'
            }
        },
    )
);

const AppFooter = () => {
    const classes = useStyles();
    //console.log("ENV: ", process.env)
    if(defaultStore.isProduction){ //From .env or DevOps build pipeline variables
        return (null)
    }
    return (
        <div className={classes.footer}>
        {`Build ${defaultStore.buildId}`}
        </div>
    );
};
export default AppFooter;
