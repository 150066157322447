import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "red",
      textAlign: "center",
      fontWeight: "bold",
    },
  })
);

const GenericNotFound  = () => {
  const classes = useStyles();
  return (
    <p className={classes.root}>
      SEEMS LIKE WE COULDN’T FIND THIS PAGE.
    </p>
  );
};

export default GenericNotFound;
