import React from 'react';
//Business
import oneviewwhite from './brand/OneviewWhite.webp'
import oneviewfull from './brand/Oneview Logo New.webp'
import oneviewclean from './brand/OneViewClean.webp'
import headerlogo from "./brand/sitelogoDCPSsm.webp";
import homeroom from "./brand/dcps_wide.webp";

// Business
export const OneViewWhite = (props:any) => (<img src={oneviewwhite} alt="oneview logo" {...props}/>)
export const OneViewFull = (props:any) => (<img src={oneviewfull} alt="oneview logo" {...props}/>)
export const OneViewClean = (props:any) => (<img src={oneviewclean} alt="oneview logo" {...props}/>)
export const Favicon = (props:any) => (<img src={headerlogo} alt="oneview logo" {...props}/>)
export const HomeRoom = (props:any) => (<img src={homeroom} alt="homeroom logo" {...props}/>)