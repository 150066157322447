import React, { useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import clsx from "clsx";
import { useMsal } from "@azure/msal-react";
import { useTheme, createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import {
    AppBar,
    Toolbar,
    IconButton,
    MenuItem,
    Menu,
    Tooltip,
    Drawer,
    Divider,
    ClickAwayListener
} from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
    DocumentCardActivity,
    IDocumentCardActivityPerson,
} from "@fluentui/react/lib/DocumentCard";

import { OneViewClean, OneViewWhite } from '../../img/Icons'
import useEventListener from '../../util/useEventListener'

import LogoutIcon from '@mui/icons-material/Logout';

const drawerWidth = 290;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            backgroundColor: theme.palette.primary.dark,

            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        toolbar: {
            minHeight: 60,
            padding: '0 20px',
        },
        appBarShift: {
            marginLeft: drawerWidth,
            //  width: `calc(100% - ${drawerWidth}px)`,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            color: "#ffffff",
            // marginLeft: "-16px",
            // [theme.breakpoints.down("xs")]: {
            //   marginLeft: "-16px",
            // },
        },
        drawerHeaderTitle: {
            backgroundColor: 'transparent',
            marginLeft: theme.spacing(1),
            marginBottom: 4, //align logo
            padding: 0,
        },
        hide: {
            display: "none",
        },
        drawer: {
            width: 0,
            flexShrink: 0,
            [theme.breakpoints.up("md")]: {
                width: drawerWidth,
            },
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create(["width", "background-color"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter, //"300ms"
            }),
            backgroundColor: theme.palette.grey[200],
        },
        drawerClose: {
            backgroundColor: theme.palette.primary.light,
            transition: theme.transitions.create(["width", "background-color"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter, //"300ms"
            }),
            overflowX: "hidden",
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(7),
            },
            [theme.breakpoints.down("sm")]: {
                width: 0,
            },
        },
        toolbarControls: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 0),
            // necessary for content to be below app bar
            //...theme.mixins.toolbar,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.common.white,
            padding: theme.spacing(0, 0),
            //...theme.mixins.toolbar,
            justifyContent: "center",
        },
        grow: {
            flexGrow: 1,
        },
        title: {
            display: "none",
            fontFamily: "Pacifico",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        sectionMobile: {
            display: "flex",
            // [theme.breakpoints.up("md")]: {
            //   display: "none",
            // },
        },
        nested: {
            paddingLeft: theme.spacing(4),
        },
        menuItem: {
            color: theme.palette.secondary.main,
            textDecoration: "none",
        },
    })
);

function handleLogout(instance: any) {
    instance.logoutRedirect().catch((e: any) => {
        console.error(e);
    });
}

export default function HomeRoomAppBar(props: any) {
    const user = useSelector((state: RootState) => state.user.user);
    const error = useSelector((state: RootState) => state.error.error);
    const theme = useTheme();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const hamburgerRef = useRef<HTMLButtonElement | null>(null);
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    let mql = window.matchMedia("(max-width: 600px)").matches;
    const [open, setOpen] = React.useState(!mql);

    const handleToggleDrawer = () => {
        setOpen((prev) => !prev);
    };

    const people: IDocumentCardActivityPerson[] = [
        { name: user?.DisplayName, profileImageSrc: user?.PhotoUrl },
    ];

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        if(mql){
            setOpen(false);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        // handleMobileMenuClose();
    };

    const mobileMenuClose = (boo: any) => {
        console.log("nav Click", boo);
        setOpen(false);
    };

    const menuId = "primary-search-account-menu";

    // Open or close menus on resize
    const windowWidth = useRef<any>();
    useEventListener(window, 'resize', () => {
        let currWidth =  window.innerWidth;
        let lastWidth = windowWidth.current
        
        if(currWidth != lastWidth){
            if (currWidth < 700 && open) {
                setOpen(false);
            }
            if (window.innerWidth > 1200 && !open) {
                setOpen(true);
            }
            windowWidth.current = window.innerWidth;
        }
       
    });

    // Close bar when click on background
    const handleClickAway = (e:any) => {
        // Exclude hamburger button itself from action
        if(mql && open && hamburgerRef.current && !hamburgerRef.current.contains(e.target)){
            e.stopPropagation();
            setOpen(false)
        }        
    }

    //right side drawer menu
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            sx={{top:50}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <div
                data-is-focusable={true}
                role="listitem"
                style={{ padding: 10, textAlign: "left", width: 140, color:"#006cb9" }}
            >

                    {/* <DocumentCardActivity activity={user?.Email} people={[people[0]]} /> */}
                    <MenuItem onClick={() => handleLogout(instance)}><LogoutIcon/><div style={{display:"flex", marginLeft: "auto", color:"black"}}>Logout</div></MenuItem>
               
            </div>
        </Menu>
    );

    return (
        <>
            <AppBar
                position="fixed"
                color="inherit"
                aria-label="open drawer"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar variant='dense' className={classes.toolbar}>
                    <div className={classes.toolbarControls}>
                        <div className={classes.drawerHeader}>
                            <IconButton
                                className={classes.drawerHeaderTitle}
                                disableRipple
                                component={Link}
                                to="/home">
                                {/* <OneViewClean style={{maxHeight: 30}}/> */}
                                <OneViewWhite style={{ maxHeight: 35 }} />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.grow} />

                    <>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                style={{ color: theme.palette.common.white }}
                            >
                                <Tooltip title="Profile">
                                    <div>
                                        <AccountCircle />
                                    </div>
                                </Tooltip>
                            </IconButton>
                        </div>
                    </>
                </Toolbar>
            </AppBar>
            {/* right side menu */}
            {renderMenu}
        </>
    );
}
