import { configureStore } from "@reduxjs/toolkit";
import errorSlice from "./error-slice";
import userSlice from "./user-slice";

import tokenSlice from "./token-slice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    token: tokenSlice.reducer,
    error: errorSlice.reducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
