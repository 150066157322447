import { useState, useEffect } from "react";

import { TextField } from "@fluentui/react/lib/TextField";
import { Spinner, SpinnerSize } from "@fluentui/react";
import classes from "../PartnerManager/PartnerManager.module.css";
import { updateOrganization, getInfo } from "../../services/POMAdmin";
import { PrimaryButton } from "@fluentui/react/lib/components/Button";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

export interface IDocument {
  key: string;
  name: string;
  value: string;
  iconName: string;
  fileType: string;
  modifiedBy: string;
  dateModified: string;
  dateModifiedValue: number;
  fileSize: string;
  fileSizeRaw: number;
  createdBy: string;
  dateCreated: string;
  dateCreatedValue: number;
  student: string;
  studentId: string;
}

export interface IOrgProps {
  isOrgAdmin: boolean;
  isGlobaleAdmin: boolean;

  currentOrg: any;
  token: string;
  onInfoChange: Function
}

const Information = (props: IOrgProps) => {
  let imageInputTemp: any;
  const token = useSelector((state: RootState) => state.token.token);

  const [displayName, setDisplayName] = useState<string>("");
  const [currentId, setCurrentId] = useState<string>("");

  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [fax, setFax] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const [ceoName, setCeoName] = useState<string>("");
  const [ceoPhone, setCeoPhone] = useState<string>("");
  const [ceoEmail, setCeoEmail] = useState<string>("");
  const [mgrdcpsdept, setMgrdcpsdept] = useState<string>("");
  const [nameWarning, setNameWarning] = useState<string>("");

  const [logoStr, setLogoStr] = useState<string>("");

  const [uploadedFileContents, setUploadedFileContents] = useState(null);

  const [showImageLoadingMessage, setShowImageLoadingMessage] =
    useState<boolean>(false);

  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.currentOrg) {
      loadOrgInfo(props.currentOrg);

      setShowLoading(false);
      setUploadedFileContents(null);
      setShowImageLoadingMessage(false);
      setNameWarning("");
    }
  }, [props.currentOrg]);

  const fileSelectedHandler = (event: any) => {
    if (event.target.files[0].size > 0) {
      setUploadedFileContents(event.target.files[0]);
      setShowImageLoadingMessage(true);
    }
  };
  const submitOrgInfoForm = (): void => {
    setShowLoading(true);

    let _o = {
      DisplayName: displayName,
      Address: address,
      State: state,
      Zip: zip,
      Contact: email,
      City: city,
      FaxNumber: fax,
      PhoneNumber: phone,
      Notes: notes,
      Properties: {
        ceoName: ceoName,
        ceoPhone: ceoPhone,
        ceoEmail: ceoEmail,
        mgrdcpsdept: mgrdcpsdept,
      },
    };

    updateOrganization(currentId, _o, uploadedFileContents, token).then(() => {
      // console.log("done updating...");
      getInfo(currentId, token).then((orgInfo) => {
        var _obj = orgInfo;
        console.log(_obj);
        //that.setState({ currentOrg: _obj, isInfoLoaded: true });
        loadOrgInfo(_obj);
      });

      setShowLoading(false);
      setUploadedFileContents(null);
      setShowImageLoadingMessage(false);
      props.onInfoChange(displayName);
    });
  };

  const loadOrgInfo = (Seed: any) => {
    if (Seed) {
      var orgInfo = Seed;

      if (orgInfo["ID"]) {
        setCurrentId(orgInfo["ID"]);
      } else {
        setCurrentId("");
      }

      if (orgInfo["DisplayName"]) {
        setDisplayName(orgInfo["DisplayName"]);
      } else {
        setDisplayName("");
      }

      if (orgInfo["Address"]) {
        setAddress(orgInfo["Address"]);
      } else {
        setAddress("");
      }

      if (orgInfo["City"]) {
        setCity(orgInfo["City"]);
      } else {
        setCity("");
      }

      if (orgInfo["State"]) {
        setState(orgInfo["State"]);
      } else {
        setState("");
      }

      if (orgInfo["Zip"]) {
        setZip(orgInfo["Zip"]);
      } else {
        setZip("");
      }

      if (orgInfo["Contact"]) {
        setEmail(orgInfo["Contact"]);
      } else {
        setEmail("");
      }

      if (orgInfo["PhoneNumber"]) {
        setPhone(orgInfo["PhoneNumber"]);
      } else {
        setPhone("");
      }

      if (orgInfo["FaxNumber"]) {
        setFax(orgInfo["FaxNumber"]);
      } else {
        setFax("");
      }

      if (orgInfo["Logo"]) {
        setLogoStr(orgInfo["Logo"]);
      } else {
        setLogoStr("");
      }
      if (orgInfo["Notes"]) {
        setNotes(orgInfo["Notes"]);
      } else {
        setNotes("");
      }

      if (orgInfo["Properties"]) {
        if (orgInfo["Properties"]["ceoPhone"]) {
          setCeoPhone(orgInfo["Properties"]["ceoPhone"]);
        } else {
          setCeoPhone("");
        }

        if (orgInfo["Properties"]["ceoName"]) {
          setCeoName(orgInfo["Properties"]["ceoName"]);
        } else {
          setCeoName("");
        }

        if (orgInfo["Properties"]["ceoEmail"]) {
          setCeoEmail(orgInfo["Properties"]["ceoEmail"]);
        } else {
          setCeoEmail("");
        }

        if (orgInfo["Properties"]["mgrdcpsdept"]) {
          setMgrdcpsdept(orgInfo["Properties"]["mgrdcpsdept"]);
        } else {
          setMgrdcpsdept("");
        }
      }
    }
  };

  const onNameChange = (event: any): void => {
    setDisplayName(event.target.value);
    event.target.value == "" ? setNameWarning("Org name is empty!") : setNameWarning("");
  };

  const onNotesChange = (event: any): void => {
    setNotes(event.target.value);
  };

  const onAddressChange = (event: any): void => {
    console.log(event);

    setAddress(event.target.value);
  };

  const onCityChange = (event: any): void => {
    setCity(event.target.value);
  };

  const onStateChange = (event: any): void => {
    setState(event.target.value);
  };

  const onZipChange = (event: any): void => {
    setZip(event.target.value);
  };

  const onCEONameChange = (event: any): void => {
    setCeoName(event.target.value);
  };

  const onCEOEmailChange = (event: any): void => {
    setCeoEmail(event.target.value);
  };

  const onCEOPhoneChange = (event: any): void => {
    setCeoPhone(event.target.value);
  };

  const onMgrDCPSDeptChange = (event: any): void => {
    setMgrdcpsdept(event.target.value);
  };

  return (
    <div className={classes.infoTable}>
      <p style={{ fontSize: "20px", color: "#006cb9" }}>
        Information: {props.currentOrg ? props.currentOrg.DisplayName : ""}{" "}
      </p>
      <TextField
        label="Name"
        required
        value={displayName}
        onChange={onNameChange}
        className={classes.textFieldMargin}
        autoComplete="off"
      />
      {nameWarning && <div className={classes.nameWarn}>{nameWarning}</div>}
      <div className={classes.divFlex}>
        <TextField
          label="Address"
          value={address}
          onChange={onAddressChange}
          className={classes.col_flex}
          autoComplete="off"
        />
        <TextField
          label="City"
          value={city}
          onChange={onCityChange}
          className={classes.col_flex}
          autoComplete="off"
        />
      </div>
      <div className={classes.divFlex}>
        <TextField
          label="State"
          value={state}
          onChange={onStateChange}
          className={classes.col_flex}
          autoComplete="off"
        />

        <TextField
          label="Zipcode"
          value={zip}
          onChange={onZipChange}
          className={classes.col_flex}
          autoComplete="off"
        />
      </div>

      <TextField
        label="CEO Name"
        value={ceoName}
        onChange={onCEONameChange}
        className={classes.textFieldMargin}
        autoComplete="off"
      />
      <div className={classes.divFlex}>
        <TextField
          label="CEO Email"
          value={ceoEmail}
          onChange={onCEOEmailChange}
          className={classes.col_flex}
          autoComplete="off"
        />

        <TextField
          label="CEO Phone Number"
          value={ceoPhone}
          onChange={onCEOPhoneChange}
          className={classes.col_flex}
          autoComplete="off"
        />
      </div>
      <TextField
        label="Managing DCPS Department"
        value={mgrdcpsdept}
        onChange={onMgrDCPSDeptChange}
        className={classes.textFieldMargin}
        autoComplete="off"
      />

      <TextField
        label="Notes"
        className={classes.textFieldMargin}
        value={notes}
        multiline
        rows={10}
        onChange={(e) => onNotesChange(e)}
        autoComplete="off"
      />

      <input
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        ref={(imageInput) => (imageInputTemp = imageInput)}
        onChange={(ev) => fileSelectedHandler(ev)}
      />

      <button
        style={{ marginLeft: "10px", marginTop: "5px" }}
        onClick={() => imageInputTemp.click()}
      >
        Upload Logo
      </button>
      <span>{showImageLoadingMessage ? "Image attached" : ""}</span>
      <div style={{ margin: "5px", width: "100%", padding: "5px" }}>
        {/* {this.state.showLoading ? <Spinner size={SpinnerSize.large} /> : null} */}
        <PrimaryButton
          ariaDescription="Save org info"
          onClick={submitOrgInfoForm}
          disabled={!props.isOrgAdmin || !!nameWarning}
          style={{
            margin: "5px",
          }}
        >
          {showLoading ? <Spinner size={SpinnerSize.large} /> : null}
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Information;
