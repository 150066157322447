let defaultStore = {
  //authentication
  token: "",
  appId: "4816732c-bbd5-4a0b-a853-51e5231ff051",
  authRedirectUrl: "http://localhost:3000",
  authAutority:
    "https://login.microsoftonline.com/duvalschoolsorg.onmicrosoft.com",
  domainHint: "duvalschools.org",

  //current enviroment
  webAPIMainEndPoint: "https://jrapi-dev-2006.azurewebsites.net",
  webAPIHostNewProd : "https://dcps-core-api-prod.azurewebsites.net",
  webAPIHostNew: "https://dcps-core-api-dev.azurewebsites.net",

  webAPIEndPointGetActivityFeed: "/api/dcps/contentstream",

  //PasswordCheck SecuritySettings.ts
  webAPIEndPointChangingPassword: "/api/profile/changepassword",
  webAPIEndPointPasswordPolicy: "/api/profile/passwordpolicy.html",
  webAPIEndPointCheckingPassword: "/api/profile/checkpassword",

  //UserProfile Update (ContactInfo.ts)
  webAPIEndPointUserProfile: "/api/profile/profile",

  //app vars (sync w/ indexdb)
  user: {}, //
  hasAdminAccess: false,
  personaObj: [{}], //
  userMenuItemsObj: {}, //
  schools: [{}],

  //Partner manager
  currentOrg: null,
  currentOrgId: "",
  pomUserAppRole: {},
  Orgs: [], //

  //Build and Envriornment-specific variables
  isProduction: process.env.REACT_APP_ENV?.toString().toUpperCase().match(/^(PROD|PRODUCTION)$/) || !process.env.REACT_APP_ENV,
  buildId: process.env?.REACT_APP_BUILD_ID ?? "NA",

  scope: "api://c9814d6d-c09a-4903-a776-533922c6c8fb/API.Access", //clientid
  scopeProd: "api://526d0547-c012-48ef-ae21-7f0247911b22/API.Access"
  //scopeprod - api://526d0547-c012-48ef-ae21-7f0247911b22/API.Access
};

defaultStore.webAPIMainEndPoint = defaultStore.webAPIHostNew;

  if(defaultStore.isProduction){
    defaultStore.webAPIMainEndPoint = defaultStore.webAPIHostNewProd;
    defaultStore.scope = defaultStore.scopeProd
}

export default defaultStore;
