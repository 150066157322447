import defaultStore from "../util/Globale";

import { encode } from "typescript-base64-arraybuffer";

export async function fetchOrgsList(token: string) {
  const response = await fetch(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations",
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json;odata=verbose",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    }
  );

  const orgs = await response.json();
  //console.log("org list", orgs);
  return orgs;
}

export async function callWebService(
  UrlPath: string,
  method: string,
  data: any = null,
  token: string
) {
  let response: any = null;

  // console.log(defaultStore.token);

  if (method === "GET" || method === "DELETE") {
    response = await fetch(UrlPath, {
      method: method,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    });
  } else if (method === "POST" || method === "PATCH" || method === "PUAT") {
    response = await fetch(UrlPath, {
      method: method,
      body: JSON.stringify(data),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    });
  }

  return response;
}

export async function updateAdminsOrganization(
  orgId: string,
  admins: string[],
  token: string
) {
  let _o = {
    ID: orgId,
    Administrators: admins,
  };

  let programInfo: any = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations/" + orgId,
    "PATCH",
    _o,
    token
  );

  const pInfo = await programInfo.json();
  //console.log(pInfo);

  return pInfo;
}

export async function updateMembersOrganization(
  orgId: string,
  members: string[],
  token: string
) {
  console.log("New members >>>> ");
  console.log(members);

  let _o = {
    ID: orgId,
    Members: members,
  };

  const response = await fetch(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations/" + orgId,
    {
      method: "PATCH",
      body: JSON.stringify(_o),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json;odata=verbose",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    }
  );

  const programInfo = await response.json();

  //that.pInfo  = await response.json();

  console.log(" ... response.json() .. ");
  console.log(programInfo);
  return programInfo;

  // return  DocsListing;
}

// to get logged in user details
export async function getUserAppRoles(token: string) {
  let appRoleInfo: any = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/AppRoles", //https://jrapi-dev-2006.azurewebsites.net/api/OutReach/AppRoles
    "GET",
    null,
    token
  );

  // console.log(appRoleInfo);
  return appRoleInfo.json();
}

export async function addOrganization(org: string, token: string) {
  console.log("Calling organization : " + org);

  let _o = {
    DisplayName: org,
  };

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations/",
    "POST",
    _o,
    token
  );
  const programInfo = await response.json();
  console.log(programInfo);
  return programInfo;

  // return  DocsListing;
}

export async function getOrgInfo(orgID: any, token: string) {
  let response = await fetch(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations/" +
      orgID,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "X-app-location": window.location.origin + window.location.pathname,
      },
    }
  );
  const orgInfo = await response.json();
  return orgInfo;
}

export async function updateOrganization(
  orgId: string,
  orgInfo: any,
  logo: any,
  token: string
) {
  console.log("Calling update organization : " + orgId);
  console.log(orgInfo);
  console.log(logo);

  // var _l =   new Blob(logo);
  let _o = orgInfo;
  _o["ID"] = orgId;

  if (logo) {
    var _x = encode(logo);

    const fd = new FormData();
    fd.append("logo", _x);

    const responseLogo = await fetch(
      defaultStore.webAPIMainEndPoint + "/api/outreach/orglogos/" + orgId,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: logo,
      }
    );

    const orgInfoUpdatedLogo = await responseLogo.json();
    console.log(orgInfoUpdatedLogo);
  }

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations/",
    "PATCH",
    _o,
    token
  );

  const orgInfoUpdated = await response.json();

  //that.pInfo  = await response.json();

  console.log(" ... response.json() .. ");
  console.log(orgInfoUpdated);
  return orgInfoUpdated;

  // return  DocsListing;
}

export async function deleteOrganization(orgId: string, token: string) {
  console.log("Deleting org : " + orgId);

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations?ID=" + orgId,
    "DELETE",
    null,
    token
  );
  const orgInfo = await response.json();

  console.log(" ... response.json() .. ");
  console.log(orgInfo);
  return orgInfo;
}

export async function processRequest(request: any, token: string) {
  console.log("Request >>>>  ");
  console.log(request);

  /*
    Decision: "Approved"
DecisionText: ""
ID: "ST-c7518c0c"
Organization: "OR-44ddc823"
  */

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/CompleteProgramRequest",
    "POST",
    request,
    token
  );
  const requestInfo = await response.json();

  //that.pInfo  = await response.json();

  console.log(" ... response.json() .. ");
  console.log(requestInfo);
  return requestInfo;

  // return  DocsListing;
}

export async function deleteRequest(request: string, token: string) {
  console.log("Request >>>>  ");
  console.log(request);

  /*
    OrganitionID: 
    Decision:Approved|Denied
    Decisio Text: 
  */

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/CompleteProgramRequest",
    "DELETE",
    request,
    token
  );
  const requestInfo = await response.json();

  //that.pInfo  = await response.json();

  console.log(" ... response.json() .. ");
  console.log(requestInfo);
  return requestInfo;

  // return  DocsListing;
}

export async function addProgram(program: string, type: string, token: string) {
  console.log("Calling program : " + program);

  let _p = {
    DisplayName: program,
    Properties: {
      Type: type,
    },
  };

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Programs/",
    "POST",
    _p,
    token
  );

  const programInfo = await response.json();

  console.log(" ... response.json() .. ");
  console.log(programInfo);
  return programInfo;

  // return  DocsListing;
}

export async function deleteProgram(program: string, token: string) {
  console.log("Calling program : " + program);

  let _p = {
    ID: program,
  };

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Programs?ID=" + program,
    "DELETE",
    _p,
    token
  );
  const programInfo = await response.json();
  console.log(programInfo);
  return programInfo;

  // return  DocsListing;
}

export async function updateProgram(program: any, token: string) {
  console.log("Calling program : " + program);
  console.log(program);

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Programs/",
    "PATCH",
    program,
    token
  );

  const programInfo = await response.json();

  //that.pInfo  = await response.json();

  console.log(" ... response.json() .. ");
  console.log(programInfo);
  return programInfo;
}

export async function addAffiliatedProgram(
  programs: string[],
  orgId: string,
  orgInfo: any,
  token: string
) {
  console.log("Calling add affiliated program  : ");
  console.log(programs);

  let _p = orgInfo;
  _p.ID = orgId;
  _p.Programs = programs;

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Organizations/" + orgId,
    "PATCH",
    _p,
    token
  );

  const programInfo = await response.json();

  console.log(programInfo);
  return programInfo;

  // return  DocsListing;
}

export async function updateAffiliatedProgram(program: any, token: string) {
  console.log("Calling program : " + program);
  console.log(program);

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Programs/",
    "PATCH",
    program,
    token
  );

  const programInfo = await response.json();

  console.log(programInfo);
  return programInfo;

  // return  DocsListing;
}

export async function getDocuments(orgId: any, token: string) {
  const response = await callWebService(
    defaultStore.webAPIMainEndPoint +
      "/api/OutReach/Students?Organization=" +
      orgId,
    "GET",
    null,
    token
  );

  const docsInfo = await response.json();

  return docsInfo;
}

export async function getPrograms(token: string) {
  console.log("Calling all Programs ...");

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Programs",
    "GET",
    null,
    token
  );

  const programsInfo = await response.json();

  let _r = {
    Programs: programsInfo,
  };

  console.log(" ... response.json() .. _r ");
  console.log(_r);

  return _r;
}

export async function getAffiliatedPrograms(orgId: string, token: string) {
  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Programs",
    "GET",
    null,
    token
  );
  const programsInfo = await response.json();

  const response2 = await callWebService(
    defaultStore.webAPIMainEndPoint +
      "/api/OutReach/Organizations/" +
      orgId +
      "/Programs",
    "GET",
    null,
    token
  );

  const programsOrgInfo = await response2.json();

  let _r = {
    Programs: programsInfo,
    OrgPrograms: programsOrgInfo,
  };

  return _r;
}

export async function getProgramStudentRequests(orgId: string, token: string) {
  // console.log("Calling getProgramsRequests : " + orgId);

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint +
      "/api/OutReach/StudentProgramRequests?Organization=" +
      orgId,
    "GET",
    null,
    token
  );

  const requestsInfo = await response.json();

  return requestsInfo;
}

export async function completeProgramsRequests(
  requestId: string,
  decision: string,
  decisionText: string,
  _requestType: string,
  token: string
) {
  console.log("Calling getProgramsRequests : " + requestId);

  let _p = {
    Id: requestId,
    Decision: decision,
    Decisiontext: decisionText,
  };

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/CompleteProgramRequests",
    "POST",
    _p,
    token
  );

  const requestsInfo = await response.json();
  console.log(requestsInfo);
  return requestsInfo;

  // return  DocsListing;
}

export async function getMembers(orgId: string, token: string) {
  // console.log("Calling getMembershipMembersfor : " + orgId);

  // console.log(orgId);

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint +
      "/api/OutReach/Organizations/" +
      orgId +
      "/Members",
    "GET",
    null,
    token
  );

  const membersInfo = await response.json();

  console.log("Returning...");
  console.log(membersInfo);

  let _r = {
    Members: membersInfo,
  };

  return _r;
}

export async function getAdmins(orgId: string, token: string) {
  //console.log("Calling get Admins for : " + orgId);

  //console.log(orgId);

  let url =
    defaultStore.webAPIMainEndPoint +
    "/api/OutReach/Organizations/" +
    orgId +
    "/Administrators";
  const responseSup = await fetch(url, {
    method: "GET",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Accept: "application/json;odata=verbose",
      Authorization: "Bearer " + token,
      "X-app-location": window.location.origin + window.location.pathname,
    },
  });

  //api/OutReach/Organizations/OR-b953d819/Administrators

  const AdministratorsInfo = await responseSup.json();

  let _r = {
    Administrators: AdministratorsInfo,
  };

  return _r;
}

export async function getStudentAndAssociation(orgId: string, token: string) {
  const response = await callWebService(
    defaultStore.webAPIMainEndPoint +
      "/api/OutReach/Students?organization=" +
      orgId,
    "GET",
    null,
    token
  );

  const studentsInfo = await response.json();

  const responseMembers = await callWebService(
    defaultStore.webAPIMainEndPoint +
      "/api/OutReach/Organizations/" +
      orgId +
      "/Members",
    "GET",
    null,
    token
  );

  const membersInfo = await responseMembers.json();

  let _r = {
    Students: studentsInfo,
    Members: membersInfo,
  };

  return _r;
}

export async function addMentorMap(
  requestId: string,
  orgId: string,
  mentorId: string,
  token: string
) {
  console.log("Calling add mentor map  : ");
  console.log(requestId);

  let _p = {
    OrganizationID: orgId,
    RequestID: requestId,
    MentorID: mentorId,
  };

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Students",
    "POST",
    _p,
    token
  );
  const mapInfo = await response.json();

  console.log(mapInfo);
  return mapInfo;

  // return  DocsListing;
}

export async function updateMentorMap(
  requestId: string,
  orgId: string,
  mentorId: string | null,
  token: string
) {
  console.log("Calling add mentor map  : ");
  console.log(requestId);
  console.log(mentorId);

  let _p = {
    OrganizationID: orgId,
    RequestID: requestId,
    MentorID: mentorId,
  };

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Students",
    "POST",
    _p,
    token
  );

  const mapInfo = await response.json();

  console.log("mapInfo ...");
  console.log(mapInfo);
  return mapInfo;

  // return  DocsListing;
}

export async function deleteMentorMap(
  requestId: string,
  orgId: string,
  MentorList: any[],
  token: string
) {
  // console.log("Calling add mentor map  : ");
  // console.log(requestId);

  let _p = {
    OrganizationID: orgId,
    RequestID: requestId,
  };

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/Students",
    "POST",
    _p,
    token
  );

  const mapInfo = await response.json();

  // console.log(mapInfo);
  return mapInfo;

  // return  DocsListing;
}

export async function getMembershipAdministrators(orgId: any, token: string) {
  // console.log("Calling getMembership Administrator : " + orgId);

  // console.log(orgId);

  if (orgId["dcpsID"]) {
    orgId = orgId["dcpsID"];
  }

  // defaultStore.currentOrg["dcpsID"] = orgI;

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint +
      "/api/OutReach//Organizations/" +
      orgId +
      "/Administrators",
    "GET",
    null,
    token
  );
  const AdministratorsInfo = await response.json();

  //that.pInfo  = await response.json();

  // console.log(" ... response.json() .. ");
  // console.log(AdministratorsInfo);
  return AdministratorsInfo;

  //return  MembershipMembersListing;
}

export async function getInfo(orgId: any, token: string) {
  //console.log("Calling getOrgInfo for: " + orgId);

  const response = await callWebService(
    defaultStore.webAPIMainEndPoint + "/api/OutReach/organizations/" + orgId,
    "GET",
    null,
    token
  );

  const orgInfo = await response.json();

  //console.log(" ... response.json() .. ");
  //console.log(orgInfo);
  return orgInfo;
}

export function isOrgAdmin(orgObj: any): boolean {
  let isOrgAdmin = false;

  /*
TODO: turn this back on
  for (var i = 0; i < defaultStore.userAppRole["AdminOf"].length; i++) {
    if (defaultStore.userAppRole["AdminOf"][i].indexOf(orgObj) > -1) {
      isOrgAdmin = true;
      break;
    }
  }

  */

  return isOrgAdmin;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
