import { Grid } from "@mui/material";
import { HomeRoom } from "../../img/Icons";
import Button from "@mui/material/Button";

import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import PersonIcon from "@mui/icons-material/Person";

import defaultStore from '../../util/Globale';

const Login = () => {
  // const ContactIcon: IIconProps = { iconName: "ContactIcon" };

  const { instance } = useMsal();

  useEffect(() => {
    handleLogin(instance);
  }, [instance]);

  const loginConfig = {
    scopes: [
      "openid",
      defaultStore.scope,
      "user.readbasic.all",
    ],
    extraQueryParameters: { domain_hint: "duvalschools.org", prompt: "" },
  };

  const handleLogin = (instance: any) => {
    instance
      .loginRedirect(loginConfig)

      .catch((e: any) => {
        // console.error(e);
      });
  };

  return (
    <Grid container className={"loginGrid"}>
      <div className={"loginGridDiv"}>
        <div className={"headerTitle"}>
          <HomeRoom width="100%" height="100%"/>
        </div>
        <div style={{ textAlign: "center" }}>
          {/* <ActionButton
            className={"logInButton"}
            iconProps={ContactIcon}
            onClick={() => handleLogin(instance)}
          >
            <b>Sign In</b>
          </ActionButton> */}
          <Button
            size="medium"
            color="primary"
            startIcon={<PersonIcon />}
            onClick={() => handleLogin(instance)}
          >
            <b>Sign In</b>
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default Login;
