import { useEffect, useState } from "react";

import classes from "./PartnerManager.module.css";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Icon } from "@fluentui/react/lib/Icon";
import {
  getAffiliatedPrograms,
  addAffiliatedProgram,
  getOrgInfo,
} from "../../services/POMAdmin";
import { Panel } from "@fluentui/react/lib/Panel";
import { Dropdown, IDropdownOption } from "@fluentui/react";

import { Spinner, SpinnerSize } from "@fluentui/react";
import { Checkbox } from "@mui/material";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";

import {
  DefaultButton,
  PrimaryButton,
  IButtonProps,
} from "@fluentui/react/lib/Button";

import { SearchBox } from "@fluentui/react/lib/SearchBox";
import {
  CommandBar,
  ICommandBarItemProps,
} from "@fluentui/react/lib/CommandBar";

import Error from "../Exceptions/TokenError";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

export interface IDetailsListAffiliatedProgramsProps {
  currentOrg: any;
  isOrgAdmin: boolean;
  isGlobaleAdmin: boolean;
  token: string;
}

export interface IAffiliatedProgram {
  key: string;
  displayName: string;
  id: string;
  studentCount: string;
  mentorCount: string;
  programType: string;
}

const AffiliatedPrograms = (props: IDetailsListAffiliatedProgramsProps) => {
  let errText: string = "";

  const token = useSelector((state: RootState) => state.token.token);
  const [affiliatedProgramsItems, setAffiliatedProgramsItems] = useState<
    IAffiliatedProgram[]
  >([]);

  const [showLoading, setShowLoading] = useState(true);
  const [checkboxValue, setCheckboxValue] = useState("");
  const [selectedKey, setSelectedKey] = useState(false);
  const [isAffiliatedPanelOpen, setIsAffiliatedPanelOpen] = useState(false);
  const [
    isAddorgAffiliatedProgramsDisabled,
    setIsAddorgAffiliatedProgramsDisabled,
  ] = useState(false);
  const [hideDeleteDialog, setHideDeleteDialog] = useState(true);
  const [
    isDeleteorgAffiliatedProgramsDisabled,
    setIsDeleteorgAffiliatedProgramsDisabled,
  ] = useState(true);
  const [currentOrgInfo, setCurrentOrgInfo] = useState({});
  const [selectedPrograms, setSelectedPrograms] = useState<any>([]);
  const [selectedProgramDelete, setSelectedProgramDelete] = useState<any>({});
  const [error, setError] = useState(false);
  const [allAffiliatedProgramsItems, setAllAffiliatedProgramsItems] =
    useState<any>([]);
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [optionsDropdown, setOptionsDropdown] = useState<any>([]);


  
  const _generateOrgAffiliatedPrograms = (items: any) => {
    let programs: IAffiliatedProgram[] = [];
    // console.log(items);
    items.map((program: any, i: any) => {
      return programs.push({
        key: i.toString(),
        id: program.ID,
        displayName: program.DisplayName,

        studentCount: program.Properties.StudentCount,
        mentorCount: program.Properties.MentorCount,
        programType: program.Properties.Type,
      });
    });

    //console.log("organized", programs);
    return programs;
  };
  useEffect(() => {
    if (props.currentOrg) {
      getAffiliatedPrograms(props.currentOrg.ID, token)
        .then((response) => {
          console.log("response members CDM", response);

          setShowLoading(false);
          setSelectedKey(false);
          setIsDeleteorgAffiliatedProgramsDisabled(true);
          setAffiliatedProgramsItems(
            _generateOrgAffiliatedPrograms(response.OrgPrograms)
          );
          setAllAffiliatedProgramsItems(
            _generateOrgAffiliatedPrograms(response.OrgPrograms)
          );

          getOrgInfo(props.currentOrg.ID, token).then((res) => {
            // console.log("org info", res);
            setCurrentOrgInfo(res);
          });

          setOptionsDropdown(getProgramDropdownOptions(response.Programs));
        })
        .catch((err: any) => {
          errText = err;
          setError(true);
        });
    }
  }, [props.currentOrg]);

  const onclickHandler = () => {
    setIsAffiliatedPanelOpen(true);
    setSelectedPrograms([]);
  };

  const _orgAffiliatedProgramsItems: ICommandBarItemProps[] = [
    {
      key: "new",
      text: "New",
      iconProps: { iconName: "Add" },
      onClick: onclickHandler,
      disabled: isAddorgAffiliatedProgramsDisabled || !props.isOrgAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
    {
      key: "delete",
      text: "Delete",
      onClick: () => setHideDeleteDialog(false),
      iconProps: { iconName: "Delete" },
      disabled: isDeleteorgAffiliatedProgramsDisabled || !props.isOrgAdmin,
      style: {
        borderColor: "rgb(244, 244, 244)",
      },
    },
  ];

  const _farItems: ICommandBarItemProps[] = [
    {
      key: "tile",
      onRender: () => (
        <SearchBox
          placeholder="Filter by program name"
          className="searchBox"
          onSearch={(newValue) => _onSearchChange(newValue)}
          onChange={(ev) => _onSearchChange(ev?.target?.value)}
        />
      ),
    },
  ];

  const modelProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 450 } },
  };

  const dialogContentProps = {
    type: DialogType.close,
    title: "Delete Program",
    subText: "Are you sure you want to delete this program?",
    style: { title: { color: "red" }, content: { color: "black" } },
  };

  const overflowProps: IButtonProps = {
    ariaLabel: "More commands",
    menuIconProps: {
      iconName: "ChevronDown",
    },
    styles: {
      menuIcon: {
        fontSize: "20px",
        color: "#0078d4",
      },
    },
  };

  const columnSort = (column: any) => {
    //alert("clicked");
    let obj = [...affiliatedProgramsItems];
    //console.log(this.state.sortAsc);
    // console.log(column);

    if (sortAsc) {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? 1 : -1;
      });
    } else {
      obj = obj.sort((a: any, b: any) => {
        let x = a[column];
        let y = b[column];
        return x > y ? -1 : 1;
      });
    }

    // console.log(obj);
    setAffiliatedProgramsItems([...obj]);

    setSortAsc(!sortAsc);
  };
  const onProgramsChange = (
    event: React.FormEvent<HTMLDivElement>,
    item: any
  ) => {
    // console.log(event);
    //console.log(item);

    //  console.log(this.state.selectedPrograms);

    if (item) {
      let selectedKeys: any = [...selectedPrograms];

      let foundIndex = selectedKeys.indexOf(item.key.toString());

      if (foundIndex > -1) {
        if (!item.selected) {
          //remove from list
          selectedKeys.splice(foundIndex, 1);
        }
      } else {
        selectedKeys.push(item.key.toString());
      }

      //console.log(selectedKeys);
      setSelectedPrograms([...selectedKeys]);
    }
  };

  const deleteAffiliatedProgram = () => {
    //console.log(this.state.selectedProgramDelete);
    let programs: any = [];
    affiliatedProgramsItems.map((item: any) => {
      return programs.push(item.id);
    });

    // console.log(programs);

    let index = programs.indexOf(selectedProgramDelete.id);

    if (index > -1) {
      programs.splice(index, 1);
    }

    // console.log("after filter", programs);

    setShowLoading(true);
    setHideDeleteDialog(true);

    addAffiliatedProgram(
      programs,
      props.currentOrg.ID,
      currentOrgInfo,
      token
    ).then((programInfo) => {
      //console.log(programInfo);
      if (programInfo["ErrorMessage"]) {
        alert(programInfo["ErrorMessage"]);
      } else if (programInfo["ExceptionMessage"]) {
        alert(programInfo["ExceptionMessage"]);
      }
      getAffiliatedPrograms(props.currentOrg.ID, props.token).then((_r) => {
        //console.log(" >> _r >> ");
        //console.log(_r);

        setAffiliatedProgramsItems(
          _generateOrgAffiliatedPrograms(_r.OrgPrograms)
        );
        setShowLoading(false);
        setSelectedKey(false);
        setIsDeleteorgAffiliatedProgramsDisabled(true);
      });
    });
  };

  const submitAffiliatedProgram = () => {
    let programs: any = selectedPrograms;
    affiliatedProgramsItems.map((item: any) => {
      return programs.push(item.id);
    });

    console.log("total program list", programs);

    setShowLoading(true);
    setIsAffiliatedPanelOpen(false);

    addAffiliatedProgram(
      programs,
      props.currentOrg.ID,
      currentOrgInfo,
      token
    ).then((programInfo) => {
      // console.log(programInfo);
      if (programInfo["ErrorMessage"]) {
        alert(programInfo["ErrorMessage"]);
      } else if (programInfo["ExceptionMessage"]) {
        alert(programInfo["ExceptionMessage"]);
      }
      getAffiliatedPrograms(props.currentOrg.ID, token).then((_r) => {
        setAffiliatedProgramsItems(
          _generateOrgAffiliatedPrograms(_r.OrgPrograms)
        );
        setShowLoading(false);
      });
    });
  };

  const getProgramDropdownOptions = (programs: any) => {
    let programsList: IDropdownOption[] = [];
    //console.log(programs);

    programs.map((program: any) => {
      return programsList.push({
        key: program.ID,
        text: program.DisplayName,
      });
    });
    return programsList;
  };

  const _onRenderFooterContent = (): JSX.Element => {
    var _r = <div>Footer</div>;

    _r = (
      <div>
        <PrimaryButton
          onClick={submitAffiliatedProgram}
          disabled={selectedPrograms.length === 0}
          style={{ marginRight: "8px" }}
        >
          Save
        </PrimaryButton>
        <DefaultButton
          onClick={() => setIsAffiliatedPanelOpen(false)}
          style={{ marginRight: "8px" }}
        >
          Cancel
        </DefaultButton>
      </div>
    );

    return _r;
  };

  const onChangeCheckbox = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    request: any
  ) => {
    //console.log("selecte key", this.state.selectedKey);
    //console.log(ev.target.value, checked, this.state.checkboxValue);

    setSelectedKey(checked);
    setCheckboxValue(ev.target.value);

    if (checked) {
      setIsDeleteorgAffiliatedProgramsDisabled(false);
      setSelectedProgramDelete(request);
    } else {
      setIsDeleteorgAffiliatedProgramsDisabled(true);
    }
  };

  const _onSearchChange = (text: any): void => {
    setAffiliatedProgramsItems(
      !!text
        ? allAffiliatedProgramsItems.filter(
            (i: any) =>
              i.displayName.toLowerCase().indexOf(text.toLocaleLowerCase()) > -1
          )
        : allAffiliatedProgramsItems
    );
  };

  return (
    <div style={{ margin: "1%" }}>
      <p style={{ fontSize: "20px", color: "#006cb9" }}>
        AffiliatedPrograms : {props.currentOrg.DisplayName}
      </p>
      <CommandBar
        items={_orgAffiliatedProgramsItems}
        farItems={_farItems}
        overflowButtonProps={overflowProps}
        ariaLabel="Use left and right arrow keys to navigate between commands"
      />
      <Table className={classes.tableList}>
        <Thead>
          <Tr>
            <Th>Select</Th>
            <Th style={{ cursor: "pointer" }} onClick={() => columnSort("id")}>
              ID{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("displayName")}
            >
              Name{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("programType")}
            >
              Program Type{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("studentCount")}
            >
              Student #{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
            <Th
              style={{ cursor: "pointer" }}
              onClick={() => columnSort("mentorCount")}
            >
              Mentor #{" "}
              <Icon iconName="Sort" className={classes.iconSmallScreen}></Icon>
            </Th>
          </Tr>
        </Thead>
        {!showLoading && (
          affiliatedProgramsItems.length > 0 && (
            affiliatedProgramsItems.map((request: any, i: any) => {
              return (
                <Tbody key={request.key}>
                  <Tr>
                    <Td>
                      <Checkbox
                        value={i}
                        className={classes.checkBox}
                        checked={
                          i.toString() === checkboxValue ? selectedKey : false
                        }
                        onChange={(e, checked) => {
                          onChangeCheckbox(e, checked, request);
                        }}
                      />
                    </Td>
                    <Td>{request.id}</Td>
                    <Td>{request.displayName}</Td>
                    <Td>{request.programType}</Td>
                    <Td>{request.studentCount}</Td>
                    <Td>{request.mentorCount}</Td>
                  </Tr>
                </Tbody>
              );
            })
          )
        )}
      </Table>
      {showLoading && (
        <div className={classes.spinnerPos}>
            <Spinner size={SpinnerSize.large}></Spinner>
        </div>)
      }
      {!showLoading && affiliatedProgramsItems.length == 0 && (
        <div className={classes.spinnerPos}>
          <b>List Empty</b>
        </div>)
      }
      {error && <Error text={errText} customText="Not able to load." />}

      <Panel
        headerText="Add Affiliated Programs"
        isOpen={isAffiliatedPanelOpen}
        onDismiss={() => setIsAffiliatedPanelOpen(false)}
        closeButtonAriaLabel="Close"
        isFooterAtBottom={false}
        onRenderFooterContent={_onRenderFooterContent}
      >
        <label htmlFor="AffiliatedPrograms" />

        <Dropdown
          placeholder="Select AffiliatedProgram(s)"
          multiSelect
          options={optionsDropdown}
          onChange={(e, item) => {
            onProgramsChange(e, item);
          }}
          required
        />
      </Panel>

      <Dialog
        hidden={hideDeleteDialog}
        onDismiss={() => setHideDeleteDialog(true)}
        modalProps={modelProps}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton
            onClick={deleteAffiliatedProgram}
            text="Delete"
            style={{
              backgroundColor: "red",
            }}
          />
          <DefaultButton
            onClick={() => setHideDeleteDialog(true)}
            text="Cancel"
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default AffiliatedPrograms;
